import React, { ReactNode, useId } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  placeholderStyle: {
    borderRadius: '4px'
  }
});

export type FormPlaceholderProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  elementsConfig: Array<'input' | 'textarea'>;
};

export function FormPlaceholder(props: FormPlaceholderProps) {
  const { elementsConfig } = props;
  const classes = useStyles();

  const elementHeighthMaps = {
    input: 40,
    textarea: 90
  };

  const keyId = useId();

  return (
    <div className={classes.formBox}>
      {elementsConfig.map((element, index) => {
        return (
          <Skeleton
            variant="rectangular"
            height={elementHeighthMaps[element]}
            className={classes.placeholderStyle}
            key={`${keyId}-${index}`}
          />
        );
      })}
    </div>
  );
}
