import { getColor } from '../../colors';

export const titleSection = {
  padding: '30px 30px 20px',
  fontSize: '18px',
  fontWeight: '600',
  fontFamily: 'Work Sans',
  lineHeight: '30px',
  color: getColor('border', 200),
  textTransform: 'capitalize',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const modalStyle = {
  '& .MuiDialog-paper': {
    maxWidth: '100%',
    borderRadius: '12px'
  }
};

export const childrenContainer = {
  padding: '0 30px 30px'
};

export const actions = {
  padding: '0px 30px'
};

export const cancelButton = {
  width: '100%',
  px: 0,
  marginRight: '6px',
  '&:hover': {
    background: getColor('primary', 50),
    borderColor: getColor('primary', 700)
  }
};

export const handleSubmitButton = {
  width: '100%',
  px: 0,
  backgroundColor: getColor('action_button', 200),
  '&:hover': {
    backgroundColor: getColor('action_button', 100),
    borderColor: getColor('action_button', 0),
    boxShadow: 'none'
  }
};
