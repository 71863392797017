import React, { FC, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { Alert, Box, Snackbar, Stack, Typography } from '@mui/material';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { ContentCopy, ContentCopyTwoTone } from '@mui/icons-material';

const ShareDealModal: FC<any> = ({ openModal, handleClose }) => {
  const [copied, setCopied] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setsnackbar(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(openModal?.split('&').slice(0, 2).join('&'));
    setCopied(true);
    setsnackbar(true);
  };

  return (
    <CommonModal isOpen={!!openModal} handleClose={handleClose} title="View Share Deal URL" hideButtons>
      <Box width={500}>
        <Snackbar
          open={snackbar}
          onClose={handleCloseSnackbar}
          message="Deal URL copied to clipboard"
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
        <Alert
          //   severity={copied ? 'success' : 'info'}
          icon={copied ? null : <></>}
          sx={{ cursor: 'pointer', '.MuiAlert-message': { width: '100%' } }}
          onClick={handleCopy}
        >
          <Stack direction="row" justifyContent="space-between">
            {openModal && (
              <Typography fontWeight={copied ? '500' : 'normal'}>
                {openModal?.split('&').slice(0, 2).join('&')}
              </Typography>
            )}
            {copied ? <ContentCopyTwoTone /> : <ContentCopy />}
          </Stack>
        </Alert>
        <Typography variant="paragraph_03_bold" pl={0.5} mt={'3px'}>
          Note: copy this link and share with your backers.
        </Typography>
        <br />
        <Box width="fit-content" margin="auto">
          <ActionButton onClick={handleClose}>OK</ActionButton>
        </Box>
      </Box>
    </CommonModal>
  );
};

export default ShareDealModal;
