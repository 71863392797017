import React, { FC, useContext, useEffect, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { Alert, Stack } from '@mui/material';
import { Dropdown } from '../../../ui-library/components/dropdowns/core-dropdown';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../manager/BackersManager';
import { toast } from 'react-toastify';
import {
  InvestmentProfileType,
  InvestmentProfileTypeOptions,
  TYPE_OF_COMMUNICATION_KEY
} from '../constants/BackersConstant';
import * as Yup from 'yup';
import { FormPlaceholder } from '../../../ui-library/components/placeholders/form-placeholder/FormPlaceholder';
import { formPlaceholderConfig } from '../../deals/constants/LaunchDealConstant';
import { API_STATUS } from '../../common/constants/CommonConstants';

const InviteBackerModal: FC<any> = ({
  title,
  openModal,
  handleClose,
  reInvite,
  handleSubmit,
  apiLoading,
  testActionType
}) => {
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);
  const [letChooseEmail, setLetChooseEmail] = useState(true);
  const [letChooseWhatsapp, setLetChooseWhatsapp] = useState(true);
  const [showWhatsapp, setShowWhatsapp] = useState(false);
  const [showError, setShowError] = useState(false);
  const [lastEmailReminder, setLastEmailReminder] = useState('');
  const [lastWhatsappReminder, setLastWhatsappReminder] = useState('');
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [testApiStatus, setTestApiStatus] = useState(API_STATUS.SUCCESS);

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    type_of_investment_profile: InvestmentProfileTypeOptions.find(
      (item: any) => item?.value === InvestmentProfileType.INDIVIDUAL
    ),
    medium: 'email',
    inviteMsg: ''
  });

  const checkCommunicationRecord = async (inv_id: any, type_of_comm: string) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const response = await backersManager.checkCommunicationRecord(inv_id, type_of_comm);
      setLetChooseEmail(response?.let_choose_email);
      setLetChooseWhatsapp(response?.let_choose_whatsapp);
      setShowWhatsapp(response?.show_whatsapp);
      setShowError(response?.show_error);
      setLastEmailReminder(response?.last_email_reminder_sent_on);
      setLastWhatsappReminder(response?.last_whatsapp_reminder_sent_on);
      setInitialValues({
        name: openModal?.name ?? '',
        email: openModal?.email ?? '',
        type_of_investment_profile: openModal?.type_of_investment_profile
          ? InvestmentProfileTypeOptions.find((item: any) => item?.value === InvestmentProfileType.INDIVIDUAL)
          : { label: '', value: '' },
        medium: response?.let_choose_email ? 'email' : response?.let_choose_whatsapp ? 'whatsapp' : '',
        inviteMsg: openModal?.inviteMsg ?? ''
      });
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error(e);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const sendTestEmailWhatsapp = async (data: any) => {
    try {
      setTestApiStatus(API_STATUS.LOADING);
      const response = await backersManager.sendTestEmailWhatsapp(data, testActionType);
      toast.success(response?.message);
      setTestApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error(e);
      setTestApiStatus(API_STATUS.SUCCESS);
    }
  };

  useEffect(() => {
    if (openModal && reInvite) {
      checkCommunicationRecord(openModal?.inv_id, TYPE_OF_COMMUNICATION_KEY[openModal?.onboarding_status]);
    }
  }, [openModal]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required')
  });

  const isRemind = title?.toLowerCase().includes('remind');

  return (
    <CommonModal submitText="continue" isOpen={openModal} handleClose={handleClose} title={title} hideButtons>
      {apiStatus === API_STATUS.LOADING ? (
        <FormContainer>
          <FormPlaceholder elementsConfig={formPlaceholderConfig} />
        </FormContainer>
      ) : (
        <FormContainer>
          {lastEmailReminder && <Alert severity="info">last email reminder sent on - {lastEmailReminder}</Alert>}
          {lastWhatsappReminder && (
            <Alert sx={{ mt: 1 }} severity="info">
              last whatsapp reminder sent on - {lastWhatsappReminder}
            </Alert>
          )}
          <br />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(form) => (
              <Stack gap={2}>
                <InputField
                  disabled={reInvite}
                  value={form?.values?.name}
                  fieldName="name"
                  id="name"
                  name="name"
                  label="Name"
                  required
                  error={!!form.errors.name}
                />
                <InputField
                  value={form?.values?.email}
                  fieldName="email"
                  id="email"
                  label="Email"
                  type="email"
                  required
                  error={!!form.errors.email}
                  disabled={reInvite}
                />

                <Dropdown
                  value={form?.values?.type_of_investment_profile}
                  fieldName="type_of_investment_profile"
                  id="type_of_investment_profile"
                  label="Type of investor"
                  options={InvestmentProfileTypeOptions}
                  disabled={reInvite}
                />

                <RadioButtonSelect
                  value={form?.values?.medium}
                  fieldName="medium"
                  id="medium"
                  formLabel={`Send ${isRemind ? 'Remind' : 'Invite'} via Email ${isRemind ? 'or WhatsApp?' : ''}`}
                  options={[
                    { name: 'Email', value: 'email', disabled: !letChooseEmail },
                    {
                      name: 'Whatsapp',
                      value: 'whatsapp',
                      disabled: !letChooseWhatsapp,
                      hide: !isRemind
                    },
                    {
                      name: 'Both',
                      value: 'both',
                      disabled: !letChooseEmail || !letChooseWhatsapp,
                      hide: !isRemind
                    }
                  ]}
                />

                {!reInvite && (form?.values?.medium == 'both' || form?.values?.medium == 'whatsapp') && (
                  <Alert severity="warning">
                    {isRemind ? 'Remind' : 'Invite'} will not be received via whatsapp if mobile number is not
                    registered on platform
                  </Alert>
                )}

                {(form?.values?.medium == 'both' || form?.values?.medium == 'whatsapp') &&
                  ((!letChooseEmail && showError) || (!letChooseWhatsapp && showError)) && (
                    <Alert severity="warning">{showError}</Alert>
                  )}

                {!isRemind && (
                  <InputField
                    value={form?.values?.inviteMsg}
                    fieldName="inviteMsg"
                    id="inviteMsg"
                    label={`Add a personalised note to the invite email${isRemind ? '/whatsapp' : ''}`}
                    multiline
                    minRows={5}
                  />
                )}

                <br />
                <Stack direction="row" gap={1} justifyContent="end">
                  {testActionType && (
                    <ActionButton
                      disabled={!form?.values?.medium || testApiStatus === API_STATUS.LOADING}
                      isLoading={testApiStatus === API_STATUS.LOADING}
                      onClick={() => {
                        form.validateForm().then((errr) => {
                          if (Object.keys(errr)?.length) {
                            form.submitForm();
                          } else {
                            sendTestEmailWhatsapp(form.values);
                          }
                        });
                      }}
                    >
                      Send Test via {form?.values?.medium === 'both' ? 'email & whatsapp' : form?.values?.medium}
                    </ActionButton>
                  )}
                  <ActionButton
                    isLoading={apiStatus === API_STATUS.LOADING || apiLoading}
                    onClick={form.handleSubmit}
                    disabled={
                      !form?.values?.medium ||
                      apiStatus === API_STATUS.LOADING ||
                      apiStatus === API_STATUS.ERROR ||
                      apiLoading
                    }
                  >
                    Send {isRemind ? 'Remind' : 'Invite'} via{' '}
                    {form?.values?.medium === 'both' ? 'email & whatsapp' : form?.values?.medium}
                  </ActionButton>
                </Stack>
              </Stack>
            )}
          </Formik>
        </FormContainer>
      )}
    </CommonModal>
  );
};

export default InviteBackerModal;
