import { Grid, Box, Checkbox } from '@mui/material';
import React from 'react';
import { checkBox, container, isPreviewClass, leftSection, paddingLeft, rightSection } from './document-styles';
import makeStyles from './document-styles';
import { getColor } from '../../colors';
export type DocumentListCardProps = {
  /**
   *unique id for document
   */
  id?: string;

  /**
   *documentName prop
   */
  documentName?: string;

  /**
   *this function is gets triggered , when you  you click on VIEW icon
   */
  handleView?: any;

  /**
   *this function is gets triggered , when you click on DOWNLOAD icon
   */
  handleDownload?: any;

  /**
   *set width of this component  , example  '1100px'
   */
  width?: string;

  /**
   *openVault Url is the string of the targeted URL when we click on  'Open in Document Vault'
   */
  openVaultURL?: string;

  /**
   * isPreview is the boolean value for showing preview option for the document'
   */
  isPreview?: boolean;

  /**
   * isCheckbox is the boolean value for showing checkbox option for the document'
   */
  isCheckbox?: boolean;

  /**
   * handleChecked is a function which get called when check box is clicked'
   */
  handleSelect?: any;

  /** isDocumentSelected is the boolean value for showing documents is selected or not'
   */
  isDocumentSelected?: boolean;
};

export function DocumentListCard({
  handleDownload,
  handleView,
  width,
  id,
  documentName,
  openVaultURL,
  isPreview,
  handleSelect,
  isCheckbox,
  isDocumentSelected
}: DocumentListCardProps) {
  const classes = makeStyles();
  const handleCheckBox = () => {
    handleSelect(id, !isDocumentSelected);
  };
  return (
    <Grid container height={'41px'} sx={{ ...container, width: width ?? '100%' }}>
      <Grid item xs={8} sm={8} md={8} lg={8} sx={isCheckbox ? leftSection : { ...leftSection, ...paddingLeft }}>
        {isCheckbox && <Checkbox checked={isDocumentSelected} onChange={handleCheckBox} size="small" sx={checkBox} />}

        <Box width="13.89px" height="15px">
          <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/File.svg" alt="err" className={classes.fullWidthHeight} />
        </Box>
        <span className={classes.documentName}>{documentName}</span>
      </Grid>
      <Grid container item sx={rightSection} xs={4} sm={4} md={4} lg={3} spacing={2}>
        {isPreview ? (
          <>
            <Grid item sx={isPreviewClass} xs={7} sm={7} md={7} lg={7}>
              <a
                href={openVaultURL}
                style={{ color: getColor('primary', 700), fontFamily: 'Work Sans' }}
                target="_blank"
              >
                Open in Document Vault
              </a>
            </Grid>
            <Grid item sx={{}}>
              {' '}
              <img
                className={classes.cursorPointer}
                width="20px"
                src="https://dsjvxb1plg419.cloudfront.net/v2.0/VeiwIcon.svg"
                alt="view icon"
                onClick={handleView}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid item sx={{ paddingRight: '20px' }}>
          {' '}
          <img
            className={classes.cursorPointer}
            width="15px"
            src="https://dsjvxb1plg419.cloudfront.net/v2.0/download-icon.svg"
            alt="download icon"
            onClick={(e: any) => {
              handleDownload(id, e);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
