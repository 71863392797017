import React from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { Skeleton } from '@mui/material';

const BackerDetailCardSkeleton = () => {
  const cardOptions = [
    {
      label: 'Investor Name',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Email',
      spacing: 6,
      renderer: <Skeleton variant="text" width="90%" height={23} />
    },
    {
      label: 'Mobile',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Go To',
      spacing: 3,
      renderer: <Skeleton variant="text" width="55%" height={23} />
    },
    {
      label: 'AIF Onboarding Status',
      spacing: 3,
      renderer: <Skeleton variant="text" width="62%" height={23} />
    },
    {
      label: 'Invited to Syndicate',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'AIF Onboarded Date',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Joined as Backer',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: '#deals',
      spacing: 3,
      renderer: <Skeleton variant="text" width="20%" height={23} />
    },
    {
      label: 'Total transferred',
      spacing: 3,
      renderer: <Skeleton variant="text" width="50%" height={23} />
    },
    {
      label: 'Unallocated Funds',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Access Public Deals?',
      spacing: 3,
      renderer: <Skeleton variant="text" width="20%" height={23} />
    },
    {
      label: 'Blocked from Syndicate',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Last Invited to Syndicate',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    }
  ];
  return <CardContainer options={cardOptions} customClass="card_border text-left" />;
};

export default BackerDetailCardSkeleton;
