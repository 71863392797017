import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    borderLeft: '1px solid #ECECEC',
    borderRight: '1px solid #ECECEC',
    borderRadius: '8px'
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ECECEC',
    padding: '10px'
  },
  lastRow: {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ECECEC',
    borderRadius: '8px',
    padding: '10px'
  },
  columnHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ECECEC',
    borderRadius: '8px 8px 0px 0px',
    padding: '10px'
  }
});

export type TablePlaceholderProps = {
  /**
   * a node to be rendered in the special component.
   */
  rows: number;
  columns: number;
  rowHeight: string;
  columnHeaderHeight: string;
};

export function TablePlaceholder({ rows, columns, rowHeight, columnHeaderHeight }: TablePlaceholderProps) {
  const styles = useStyles();
  return (
    <div>
      <Grid container className={styles.container}>
        <Grid container height={columnHeaderHeight} className={styles.columnHeader}>
          {[...Array(columns)].map((key, index) => (
            <Grid item sm={2} key={index}>
              <Skeleton width={72} height={20} />
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {[...Array(rows)].map((key, index) => {
            return (
              <Grid
                container
                height={rowHeight}
                className={index !== rows - 1 ? styles.row : styles.lastRow}
                key={index}
              >
                {[...Array(columns)].map((key, index) => (
                  <Grid item sm={2} key={index}>
                    <Skeleton width={72} height={20} />
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
}
