export enum DealStatusEnum {
  ALL = 'all',
  ONGOING = 'ongoing',
  SAVED = 'saved',
  FUNDED = 'funded',
  CLOSED = 'closed',
  CANCELLED = 'canceled'
}

export const DealStatusEnumLabel: any = {
  [DealStatusEnum.ALL]: 'All',
  [DealStatusEnum.ONGOING]: 'Live',
  [DealStatusEnum.SAVED]: 'Draft',
  [DealStatusEnum.FUNDED]: 'Funded',
  [DealStatusEnum.CLOSED]: 'Closed',
  [DealStatusEnum.CANCELLED]: 'Cancelled'
};

export const CountryRegTypeOptions = [
  { label: 'India', value: 'true' },
  { label: 'A country other than India', value: 'false' }
];

export const StartupStageOptions = [
  { label: 'Ideation', value: 1 },
  { label: 'Proof of Concept', value: 2 },
  { label: 'Beta Launched', value: 3 },
  { label: 'Early Revenues', value: 4 },
  { label: 'Steady Revenues', value: 5 }
];

export const BusinessTypeOptions = [
  { label: 'B2B', value: 'B2B' },
  { label: 'B2C', value: 'B2C' },
  { label: 'D2C', value: 'D2C' },
  { label: 'P2P', value: 'P2P' },
  { label: 'B2G', value: 'B2G' },
  { label: 'B2B2C', value: 'B2B2C' },
  { label: 'B2B2G', value: 'B2B2G' }
];

export const SecurityTypeOptions = [
  { label: 'Equity', value: 'equity' },
  { label: 'CCPS', value: 'ccps' },
  { label: 'CCD', value: 'ccd' },
  { label: 'Convertible Note', value: 'convertible_debt' }
];
