import { makeStyles } from '@mui/styles';
import { getColor } from '../../colors';

export default makeStyles({
  cursorPointer: {
    cursor: 'pointer'
  },
  fullWidthHeight: {
    height: '100%',
    width: '100%'
  },
  documentName: {
    paddingLeft: '0.5rem'
  }
});

export const paddingLeft = {
  paddingLeft: '1rem'
};

export const container = {
  backgroundColor: getColor('background', 100),
  alignItems: 'center',
  borderRadius: '4px'
};

export const leftSection = {
  fontSize: '13px',
  color: getColor('border', 300),
  fontFamily: 'Work Sans',
  display: 'flex',
  alignItems: 'center'
};

export const rightSection = {
  fontSize: '14px',
  justifyContent: 'flex-end'
};

export const isPreviewClass = { fontSize: '14px', textAlign: 'end' };

export const checkBox = {
  color: getColor('primary', 500),
  '&.Mui-checked': {
    color: getColor('primary', 700)
  }
};

export const titleStyle = {
  padding: '30px 30px 8px',
  fontSize: '18px',
  fontWeight: '600',
  fontFamily: 'Work Sans',
  lineHeight: '30px',
  color: getColor('border', 200),
  textTransform: 'capitalize'
};
