import { Typography } from '@mui/material';
import React from 'react';

const RejectReasonTooltip = () => {
  return (
    <>
      <Typography variant="paragraph_03_bold">Your startup is rejected. Please go through the next steps:</Typography>
      <Typography variant="caption_regular">
        1: Review the rejection reason, update the startup and scheme documents accordingly, and save the changes
      </Typography>
      <Typography variant="caption_regular">2: Click ‘Submit for Approval’ once it is ready for LV review</Typography>
      <Typography variant="caption_regular">
        <i>For more details email to contactus@letsventure.com</i>
      </Typography>
    </>
  );
};

export default RejectReasonTooltip;
