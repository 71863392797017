import React, { FC, useContext, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { useNavigate } from 'react-router-dom';
import ActionContainer from '../../common/components/ActionContainer';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import ManageCommitModal from '../modals/ManageCommitModal';
import LiveDeal from './LiveDeal';
import FilterChip from '../../../ui-library/components/filter-chip/FilterChip';
import { CheckCircleOutline } from '@mui/icons-material';
import {
  BackersForDealStatus,
  ManageCommitOptions,
  TYPE_OF_COMMUNICATION_KEY
} from '../constants/IndividualDealConstant';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import InviteBackerModal from '../../backers/modals/InviteBackerModal';
import { AuthContext } from '../../../contexts/authContext';
import BackersManager from '../../backers/manager/BackersManager';
import { toast } from 'react-toastify';
import BulkInviteBackerModal from '../../backers/modals/BulkInviteBackerModal';
import DealsManager from '../manager/DealsManager';
import SendDrawdownModal from '../modals/SendDrawdownModal';
import { API_STATUS } from '../../common/constants/CommonConstants';
import moment from 'moment';

const StartupResponse: FC<any> = ({
  startupData,
  backersForDeal,
  syncAdminsForDeal,
  apiStatusBackerData,
  communicationLogs,
  getDealData
}) => {
  const [filters, setFilters] = useState([]);
  const [showManageCommitModal, setSMCM] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState<any>(false);
  const [showBulkReminderModal, setSBRM] = useState<any>(false);
  const [sendDrawdownModal, setSendDrawdownModal] = useState<any>(false);
  const [selectedBackers, setSelectedBackers] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(BackersForDealStatus.INVITED);
  const [submitApiStatus, setSubmitApiStatus] = useState(API_STATUS.SUCCESS);

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const backersManager = new BackersManager(authContext);
  const dealManager = new DealsManager(authContext);

  const handleViewCommunicationLogs = () => {
    navigate(`/deals/${startupData?.id}/communication-logs`, {
      state: { startupData, communication_arr: communicationLogs }
    });
  };
  const handleInviteBackersToDeal = () => {
    navigate(`/deals/${startupData?.id}/invite-backers`, { state: { startupData, syncAdminsForDeal } });
  };

  const getBackersForDeal = (backersForDeal: any) => {
    if (!backersForDeal) {
      return [];
    }
    return backersForDeal.filter((backer: any) => backer?.backer_status === selectedStatus);
  };

  const getFilterCount = (status: string) => {
    if (!backersForDeal) {
      return 0;
    }
    return backersForDeal.filter((backer: any) => backer?.backer_status === status)?.length;
  };

  const getDrawdownSentCount = () => {
    return (
      backersForDeal.filter(
        (backer: any) => backer?.backer_status === BackersForDealStatus.COMMITED && backer?.drawdown_sent
      )?.length ?? 0
    );
  };

  const handleSubmitRemind = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.reInviteMember(
        showReminderModal?.inv_id,
        values?.medium,
        TYPE_OF_COMMUNICATION_KEY[showReminderModal?.backer_status],
        startupData?.id,
        showReminderModal?.backer_status === BackersForDealStatus.COMMITED ? showBulkReminderModal?.inv_commit_id : '',
        true
      );
      toast.success(response?.message);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      setShowReminderModal(false);
    } catch (e: any) {
      toast.error(e);
      setShowReminderModal(false);
    }
  };

  const handleSubmitBulkRemind = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      const response = await backersManager.bulkReInviteMember(
        selectedBackers,
        values?.medium,
        TYPE_OF_COMMUNICATION_KEY[showReminderModal?.backer_status],
        startupData?.id,
        '',
        true
      );
      toast.success(response?.data);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      setSBRM(false);
    } catch (e: any) {
      setSBRM(false);
      toast.error(e?.detail);
    }
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      renderCell: (params: any) => (
        <LinkButton title={params?.row?.name} onClick={() => navigate(`/backers/${params?.row?.inv_id}`)} />
      )
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 300,
      headerName: 'EMAIL'
    },
    {
      field: 'invited_date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 200,
      headerName: 'INVITED TO DEAL',
      renderCell: (params: any) =>
        params?.row?.formated_invited_date ?? moment(params?.row?.invited_date).format('MMM DD, YYYY'),
      valueGetter: (params: any) => params?.row?.invited_date
    },
    {
      field: 'manage_commit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'COMMITMENT',
      renderCell: (params: any) =>
        params?.row?.manage_commit_amount &&
        `${params?.row?.manage_commit_condition} : ${params?.row?.formatted_manage_commit_amount}`
    },
    {
      field: 'committed_amount',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'COMMITTED',
      renderCell: (params: any) => params?.row?.formated_committed_amount
    },
    {
      field: 'committed_date',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'COMMIT DATE',
      type: 'date',
      renderCell: (params: any) => params?.row?.formated_committed_date,
      valueGetter: (params: any) => params?.row?.committed_date
    },
    {
      field: 'drawdown_sent_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'DRAWDOWN SENT',
      renderCell: (params: any) => params?.row?.formated_drawdown_sent_date,
      valueGetter: (params: any) => params?.row?.drawdown_sent_date
    },
    {
      field: 'money_received_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      type: 'date',
      headerName: 'TRANSFER TO AIF',
      renderCell: (params: any) => params?.row?.formated_money_received_date,
      valueGetter: (params: any) => params?.row?.money_received_date
    },

    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: any) => {
        return (
          <ActionContainer>
            {([BackersForDealStatus.INVITED, BackersForDealStatus.VIEWED].includes(params?.row?.backer_status) ||
              (params?.row?.backer_status === BackersForDealStatus.COMMITED && !!params?.row?.drawdown_sent)) && (
              <LinkButton
                title="remind"
                onClick={() => {
                  setShowReminderModal(params?.row);
                }}
              />
            )}

            {params?.row?.backer_status === BackersForDealStatus.COMMITED && !params?.row?.drawdown_sent && (
              <LinkButton
                title="send drawdown"
                onClick={() => {
                  setSendDrawdownModal(params?.row);
                }}
              />
            )}

            {[BackersForDealStatus.INVITED, BackersForDealStatus.VIEWED].includes(params?.row?.backer_status) && (
              <LinkButton
                onClick={() => {
                  const rowData = params?.row ?? {};
                  rowData.condition = ManageCommitOptions?.find(
                    (item: any) => item?.value == rowData?.manage_commit_condition_int
                  );
                  rowData.proposed_amount = rowData?.manage_commit_amount;
                  setSMCM(rowData);
                }}
                title="manage commit"
              />
            )}
          </ActionContainer>
        );
      }
    }
  ];

  const manageCommit = async (values: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      await dealManager.manageCommitement(startupData?.id, showManageCommitModal, values);
      toast.success('Successfully updated commitment');
      setSMCM(false);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      getDealData && (await getDealData());
    } catch (e: any) {
      toast.error(e);
      setSMCM(false);
    }
  };

  const handleSendDrawdown = async (transferByDate: any) => {
    try {
      setSubmitApiStatus(API_STATUS.LOADING);
      await dealManager.sendDrawdown(sendDrawdownModal?.inv_commit_id, transferByDate);
      toast.success('Drawdown sent successfully');
      setSendDrawdownModal(false);
      setSubmitApiStatus(API_STATUS.SUCCESS);
      getDealData && (await getDealData());
    } catch (e: any) {
      toast.error(e?.detail);
      setSendDrawdownModal(false);
    }
  };

  return (
    <>
      <BulkInviteBackerModal
        title="Remind Backers to this Deal"
        selectedRows={selectedBackers}
        openModal={showBulkReminderModal}
        reInvite
        handleSubmit={handleSubmitBulkRemind}
        apiLoading={submitApiStatus === API_STATUS.LOADING}
        handleClose={() => {
          setSBRM(false);
        }}
      />
      {showReminderModal && (
        <InviteBackerModal
          title={
            [BackersForDealStatus.INVITED, BackersForDealStatus.VIEWED]?.includes(showReminderModal?.backer_status)
              ? 'Remind backer to commit into this deal'
              : 'Remind backer to transfer Drawdown amount'
          }
          openModal={showReminderModal}
          reInvite
          apiLoading={submitApiStatus === API_STATUS.LOADING}
          handleClose={() => {
            setShowReminderModal(false);
          }}
          handleSubmit={handleSubmitRemind}
        />
      )}
      {sendDrawdownModal && (
        <SendDrawdownModal
          openModal={sendDrawdownModal}
          handleClose={() => {
            setSendDrawdownModal(false);
          }}
          handleSubmit={handleSendDrawdown}
          apiLoading={submitApiStatus === API_STATUS.LOADING}
        />
      )}
      <ManageCommitModal
        openModal={showManageCommitModal}
        handleClose={() => {
          setSMCM(false);
        }}
        handleSubmit={manageCommit}
        apiLoading={submitApiStatus === API_STATUS.LOADING}
      />

      <LiveDeal startupData={startupData} />

      {apiStatusBackerData === API_STATUS.LOADING ? (
        <Box>
          <SectionHeader customStyles={{ textAlign: 'start', marginBottom: 2 }}>Backers for this Deal</SectionHeader>

          <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
        </Box>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <SectionHeader customStyles={{ textAlign: 'start' }}>Backers for this Deal</SectionHeader>
            <Box gap={1} display="flex">
              {([BackersForDealStatus.INVITED, BackersForDealStatus.VIEWED].includes(selectedStatus) ||
                (selectedStatus === BackersForDealStatus.COMMITED && getDrawdownSentCount() > 0)) && (
                <ActionButton disabled={!selectedBackers?.length} onClick={() => setSBRM(true)}>
                  Send Bulk Reminder
                </ActionButton>
              )}
              <ActionButton onClick={handleViewCommunicationLogs}>View Communications Log</ActionButton>

              <ActionButton onClick={handleInviteBackersToDeal}>Invite Backers to this Deal</ActionButton>
            </Box>
          </Stack>
          <Stack direction="row" gap={2} width="fit-content" alignItems="center" mb={2} mt={1}>
            <Typography fontSize="14px">Filter By Status:</Typography>

            <FilterChip
              label={`Invited (${getFilterCount(BackersForDealStatus.INVITED)})`}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === BackersForDealStatus.INVITED}
              onClick={() => {
                setSelectedStatus(BackersForDealStatus.INVITED);
              }}
            />
            <FilterChip
              label={`Viewed (${getFilterCount(BackersForDealStatus.VIEWED)})`}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === BackersForDealStatus.VIEWED}
              onClick={() => {
                setSelectedStatus(BackersForDealStatus.VIEWED);
              }}
            />
            <FilterChip
              label={`Committed (${getFilterCount(BackersForDealStatus.COMMITED)})`}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === BackersForDealStatus.COMMITED}
              onClick={() => {
                setSelectedStatus(BackersForDealStatus.COMMITED);
              }}
            />
            <FilterChip
              label={`Transferred (${getFilterCount(BackersForDealStatus.TRANSFERRED)})`}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === BackersForDealStatus.TRANSFERRED}
              onClick={() => {
                setSelectedStatus(BackersForDealStatus.TRANSFERRED);
              }}
            />
          </Stack>

          <LvComplexTable
            rows={getBackersForDeal(backersForDeal)}
            columns={columnsData}
            leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
            activeFilters={filters}
            // filterMode="server"
            checkboxSelection
            onselectionChange={(selectedRows: any) => setSelectedBackers(selectedRows)}
            isRowSelectable={(params: any) => {
              return !params?.row?.drawdown_sent || !params?.row?.admin;
            }}
            // enableAdvanceNumberFiltering
            onFilterChange={(filters: any) => setFilters(filters)}
            rowHeight={48}
            headerHeight={50}
            getRowClassName={(params: any) => {
              return params?.row?.admin ? 'isAdmin' : '';
            }}
            customStyles={{
              '.isAdmin': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                ':hover::after': {
                  display: 'block',
                  position: 'absolute',
                  content: '"This user is an Admin for this syndicate. Values cannot be modified for them."',
                  border: '1px solid #ccc',
                  background: '#fff',
                  padding: '.25em',
                  borderRadius: '.25em'
                }
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default StartupResponse;
