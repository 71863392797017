import React, { FC } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { Stack } from '@mui/material';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import * as Yup from 'yup';
import { useStyles } from './styles';
import { FileUpload } from '../../../ui-library/components/form-elements/file-upload/FileUpload';

const BulkInviteBackerModal: FC<any> = ({
  title,
  openModal,
  handleClose,
  reInvite,
  selectedRows,
  handleSubmit,
  apiLoading
}) => {
  const classes = useStyles();
  const initialValues = { file: undefined, mode_of_comm: 'email' };
  const validationSchema = Yup.object({
    file: Yup.mixed().required()
  });

  return (
    <CommonModal
      submitText="continue"
      isOpen={openModal}
      handleClose={handleClose}
      title={`${title} ${reInvite && selectedRows ? '(' + selectedRows?.length + ' backers selected)' : ''}`}
      hideButtons
    >
      <FormContainer>
        <Formik initialValues={initialValues} validationSchema={!reInvite && validationSchema} onSubmit={handleSubmit}>
          {(form) => (
            <Stack gap={2}>
              {!reInvite && (
                <FileUpload
                  accept={{ 'text/excel': ['.xltx', '.xls', '.xlsx', '.xlsb', '.xlsm'] }}
                  title="upload excel file (max size 2 MB)"
                  selectedFile={(file: any) => form.setFieldValue('file', file)}
                  value={form?.values?.file}
                  label="Upload excel sheet with backers details"
                  helperText="Note: backers who are already invited will not be sent any email"
                  error={!!form?.errors?.file}
                />
              )}
              {JSON.stringify(form?.errors.file)}
              <RadioButtonSelect
                fieldName="mode_of_comm"
                id="mode_of_comm"
                value={form?.values?.mode_of_comm}
                formLabel="Send Invite via Email?"
                options={[{ name: 'Email', value: 'email' }]}
              />

              <br />
              <Stack direction="row" gap={1} justifyContent="end">
                <ActionButton>Send Test Email</ActionButton>
                <ActionButton onClick={form.handleSubmit} isLoading={apiLoading} disabled={apiLoading}>
                  Send Invite Email
                </ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default BulkInviteBackerModal;
