export type ActionOptionType = {
  label: string;
  disabled?: boolean;
  subText?: string;
  subTextStyle?: any;
  labelStyle?: any;
};

export enum ActionOptions {
  SEND_REINVITE_EMAIL = 'Send Re-Invite Email',
  SEND_REINVITE_WHATSAPP = 'Send Re-invite on WhatsApp',
  SEND_REMIND_EMAIL = 'Send Remind Email',
  SEND_REMIND_WHATSAPP = 'Send Remind on WhatsApp'
}

export const ActionOptionsListInvite = (letChooseEmail: any, letChoseWhatsapp: any): ActionOptionType[] => {
  return [
    {
      label: ActionOptions.SEND_REINVITE_EMAIL,
      disabled: !letChooseEmail
    },
    {
      label: ActionOptions.SEND_REINVITE_WHATSAPP,
      disabled: !letChoseWhatsapp
    }
  ];
};

export const ActionOptionsListCaSent = (letChooseEmail: any, letChoseWhatsapp: any): ActionOptionType[] => {
  return [
    {
      label: ActionOptions.SEND_REMIND_EMAIL,
      disabled: !letChooseEmail
    },
    {
      label: ActionOptions.SEND_REMIND_WHATSAPP,
      disabled: !letChoseWhatsapp
    }
  ];
};
