import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { getColor } from '../../colors';

export interface NoDataAvailableProps {
  /**
 * Title Name as a String.
 * e.g. 
          "No Data Available",
        
 */
  title: string;
  /**
 * SubTitle Name as a String.
 * e.g. 
          "No Cap Table data found. Please add data.",
        
 */
  subTitle?: string;
  /**
 * Give image URL path into string.
 * e.g. 
          "https://cdbgf.nhgftr.123.png",
        
 */
  image?: string;

  /**
   * Custom component to render instead of image
   */
  imageComponent?: ReactNode;

  /**optional button components */
  buttonComponent?: any;
}

export function NoDataAvailable(props: NoDataAvailableProps) {
  const { title, subTitle, image, imageComponent, buttonComponent } = props;
  return (
    <Box
      className="no-data-available"
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      textAlign={'center'}
      height="100%"
      alignSelf={'center'}
    >
      <div>
        {!imageComponent && image && (
          <img className="image-data" src={image} width={80} alt="no-data" style={{ margin: 'auto' }} />
        )}
        {imageComponent && <div>{imageComponent}</div>}
        <Typography
          variant="h2"
          component="h2"
          fontSize={'1.125rem'}
          fontWeight={600}
          color={getColor('border', 200)}
          marginTop="12px"
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography fontSize="14px" color="#4f5151" marginTop="4px">
            {subTitle}
          </Typography>
        )}
        {buttonComponent && <Box sx={{ py: 2 }}>{buttonComponent}</Box>}
      </div>
    </Box>
  );
}

NoDataAvailable.defaultProps = {
  title: 'No Data Available',
  image: null,
  imageComponent: null
};
