const BASE_URL = 'https://dsjvxb1plg419.cloudfront.net/v2.0';
export const IconConstant = {
  BELL_ICON: `${BASE_URL}/NotificationBell.svg`,
  USER_PROFILE_ICON: `${BASE_URL}/Group%201143.svg`,
  DATA_NOT_FOUND_ICON: `${BASE_URL}/data-not-found%201.svg`,
  SEARCH_ICON: `${BASE_URL}/search-icon.svg`,
  ALERT_DELETE_ICON: `${BASE_URL}/alert-delete-icon.svg`,
  HISTORICAL_DATA_ICON: `${BASE_URL}/Vector-3.svg`,
  FILTER_ICON: `${BASE_URL}/filter-icon.svg`,
  SETTINGS_ICON: `${BASE_URL}/ColumnSettingsIcon.svg`,
  SETTINGS_PLUS_ICON: `${BASE_URL}/Group 1000003641.svg`,
  EDIT_ICON: `${BASE_URL}/EditBlue.svg`,
  EDIT_ICON_2: `${BASE_URL}/EditDarkBlue.svg`,
  DOWNLOAD_ICON: `${BASE_URL}/download-icon.svg`,
  UPLOAD_ICON: `${BASE_URL}/upload_icon_blue.svg`,
  RUPEE_ICON: `${BASE_URL}/rupee.svg`,
  DELETE_RED_ICON: `${BASE_URL}/DeleteRed.svg`,
  GREEN_CIRCLE_CHECK: `${BASE_URL}/GreenCheck.svg`,
  CALENDER: `${BASE_URL}/CalendarIcon.svg`,
  SUCCESS_ELLIPSE: `${BASE_URL}/Success.svg`,
  LIGHT_BULB: `${BASE_URL}/bulb-icon.svg`,
  EYE_ICON: `${BASE_URL}/VeiwIcon.svg`,
  HIDE_EYE_ICON: `${BASE_URL}/password-hide-icon.svg`,
  COPY_ICON: `${BASE_URL}/CopyIcon.svg`,
  BLOCK_ICON: `${BASE_URL}/block_icon.svg`,
  GREEN_CHECK_MARK_ICON: `${BASE_URL}/tick_check_mark_accept_icon.svg`,
  ERROR_ICON: `${BASE_URL}/error_icon.svg`,
  WARNING_ICON: `${BASE_URL}/Error.svg`,
  WARNING_WHITE_ICON: `${BASE_URL}/ErrorWhite.svg`,
  WARNING_BROWN_ICON: `${BASE_URL}/warning-brown.svg`,
  REMINDER_ICON: `${BASE_URL}/Reminder.svg`,
  INFO_ICON: `${BASE_URL}/InfoBlueIcon.svg`,
  NO_DATA_ICON: `${BASE_URL}/no-data-icon.svg`,
  FOLDER_ICON: `${BASE_URL}/folder_icon_black.svg`,
  ADD_DATA: `${BASE_URL}/add-data.svg`,
  COMING_SOON_ICON: `${BASE_URL}/Coming_Soon.svg`,
  COMING_SOON_NO_BGR: `${BASE_URL}/Coming_Soon_No_Bgr.svg`,
  COMING_SOON_LABEL: `${BASE_URL}/coming_soon_label.svg`,
  COMING_SOON_RED_BGR: `${BASE_URL}/coming_soon_red_bgr.svg`,
  WHITE_TICK: `${BASE_URL}/white-tick.svg`,
  BUYBACK_PROGRAM: `${BASE_URL}/buyback-program.svg`,
  LIQUIDITY_PROGRAM: `${BASE_URL}/liquidity-program.svg`,
  CHECK_ICON: `${BASE_URL}/CelebrateCheck.svg`,
  CELEBRATE_ONE: `${BASE_URL}/CelebrateOne.svg`,
  CELEBRATE_TWO: `${BASE_URL}/CelebrateTwo.svg`,
  WAVE_EMOJI: `${BASE_URL}/WaveEmoji.svg`,
  TRICA_ROUND_LOGO: `${BASE_URL}/tricaRoundLogo.svg`,
  RELOAD: `${BASE_URL}/Reload.svg`,
  REFRESHBROWSER: 'https://dsjvxb1plg419.cloudfront.net/refresh-browser.svg',
  MAIL_ICON: `${BASE_URL}/email 1.svg`,
  CALL_ICON: `${BASE_URL}/phone-call 1.svg`,
  RENEW_PLAN: `${BASE_URL}/admin_restriction.svg`,
  PAGE_NOT_FOUND: `${BASE_URL}/page-not-found.svg`
};
