import { useEffect, useContext } from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { setHeader, setInterceptors } from './api/interceptor';
import AppRoutes from './routes';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MUI_X_PRO_LICENSE_KEY } from './app/common/constants/CommonConstants';
import { useQuery } from './common/hooks/useQuery';
import ImpersonateManager from './common/manager/impersonateManager';
import { AuthContext } from './contexts/authContext';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function App() {
  const authContext = useContext(AuthContext);
  const { setUserSession } = authContext;
  const impersonateManager = new ImpersonateManager();
  const queryParams = useQuery();
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_GLASSBOARD_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_GLASSBOARD_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_GLASSBOARD_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error('Missing Auth0 environment variables');
  }

  const appRedirect = async () => {
    try {
      const accessTokenSwitchUser: any = !!queryParams.get('access_token')
        ? queryParams.get('access_token')?.replaceAll(' ', '+')
        : queryParams.get('access_token');
      const accessSwitchUser = queryParams.get('access');
      if (accessSwitchUser) {
        const response = await impersonateManager.impersonateUserLogin(accessTokenSwitchUser);
        if (!isEmpty(response)) {
          setUserSession(
            response.data.access_token,
            response.user,
            response.syndicate_id,
            response.syndicate_type,
            response.syndicate_permalink,
            response.data.refresh_token
          );
          navigate('/');
        }
      }
    } catch (error) {
      console.error('Error in redirecting', error);
      toast.error('Error in redirecting');
    }
  };

  useEffect(() => {
    appRedirect();
  }, []);

  useEffect(() => {
    setHeader();
    setInterceptors();
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  const onRedirectCallback = (appState?: AppState) => {
    window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: `${window.location.origin}/callback`
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
    >
      <div className="App">
        <AppRoutes />
      </div>
    </Auth0Provider>
  );
}

export default App;
