import { useState } from 'react';
import { Chip, Autocomplete } from '@mui/material';
// import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  helperText: {
    marginTop: '4px',
    marginLeft: '1px'
  },
  minHeight: {
    minHeight: '89px',
    alignItems: 'baseline'
  }
});

interface AutocompleteTagsProps {
  defaultValue?: string[];
  dropdownOptions?: string[];
  formikValues: {
    [key: string]: string[]; // Adjust this type if the values are not always arrays of strings
  };
  tagsKey?: string;
  label?: string;
  classes?: any; // You may want to replace `any` with a more specific type if possible
  chipStyle?: React.CSSProperties | null;
  deleteIcon?: React.ReactNode | null;
  helperText?: string | null;
  formHelperTextProps?: any; // Adjust the type based on your use case
  onChange?: any;
  maxTagLength?: number | null;
}

const AutocompleteTags: React.FC<AutocompleteTagsProps> = ({
  defaultValue = [],
  dropdownOptions = [],
  formikValues = {},
  tagsKey = 'tags',
  label = 'Tags',
  classes = null,
  chipStyle = null,
  deleteIcon = null,
  helperText = null,
  formHelperTextProps = null,
  onChange = (value: any) => {
    // do nothing
  },
  maxTagLength = null
}) => {
  const componentClasses = useStyles();
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      id={tagsKey}
      multiple
      defaultValue={defaultValue}
      freeSolo
      value={formikValues[tagsKey] || []} // Safe indexing
      inputValue={inputValue}
      options={Array.from(new Set([...dropdownOptions, ...(formikValues[tagsKey] || [])]))} // Safe indexing
      onChange={(e: any, newValue: any) => {
        formikValues[tagsKey] = newValue;
        onChange(formikValues[tagsKey]);
      }}
      onInputChange={(e, newInputValue) => {
        const options = newInputValue.split(',');

        if (options.length > 1) {
          const value = options[0].trim();
          if (value && !formikValues[tagsKey]?.includes(value)) {
            formikValues[tagsKey].push(value);
          }
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      // classes={classes ?? { inputRoot: componentClasses.minHeight }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            label={<span>{option}</span>}
            style={
              chipStyle ?? {
                borderRadius: '4px',
                backgroundColor: '#E7F3FF',
                color: '#004F9F',
                fontWeight: 500
              }
            }
            //   deleteIcon={deleteIcon ?? <CloseSharpIcon fontSize="small" />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, maxLength: maxTagLength }}
            label={label}
            sx={{ '.MuiInputBase-root': { alignItems: 'flex-start' } }}
            helperText={helperText ?? 'Note : Enter comma after each tag'}
            FormHelperTextProps={formHelperTextProps ?? { classes: { root: componentClasses.helperText } }}
            multiline
            minRows={4}
          />
        );
      }}
    />
  );
};

export default AutocompleteTags;
