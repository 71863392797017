import React, { FC, useContext, useEffect, useState } from 'react';
import { InputField } from '../../../../ui-library/components/form-elements/CustomTextField';
import { Dropdown } from '../../../../ui-library/components/dropdowns/core-dropdown';
import { FileUpload } from '../../../../ui-library/components/form-elements/file-upload/FileUpload';
import { RadioButtonSelect } from '../../../../ui-library/components/form-elements/RadioButtonSelect';
import { Form, Formik, Field } from 'formik';
import { Box, Divider, FormLabel, InputAdornment, Stack, Typography } from '@mui/material';
import { ActionButton } from '../../../../ui-library/components/buttons/action-button';
import { BusinessTypeOptions, DealStatusEnum, StartupStageOptions } from '../../constants/DealsConstant';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../contexts/authContext';
import DealsManager from '../../manager/DealsManager';
import * as Yup from 'yup';
import { FormPlaceholder } from '../../../../ui-library/components/placeholders/form-placeholder/FormPlaceholder';
import { formPlaceholderConfig } from '../../constants/LaunchDealConstant';
import { DealTabType } from '../../constants/IndividualDealConstant';
import { PhoneNumber } from '../../../../ui-library/components/form-elements/phone-number/PhoneNumber';
import { API_STATUS } from '../../../common/constants/CommonConstants';
import { isValidMobileNumber } from '../../../common/utils/commonUtils';
import { getColor } from '../../../../ui-library/colors';
import { TimePeriodDropdown } from '../../../../ui-library/components/dropdowns/time-period-dropdown';

const initialValuesConstant = {
  startupName: '',
  entityName: '',
  registrationCountry: null,
  startupLocationTokens: null,
  startupStage: null,
  startupSectors: null,
  businessType: null,
  startupExistence: null,
  revenueCheck: null,
  founderName: '',
  founderEmail: '',
  founderPhoneNumber: '',
  startupProfileImage: [],
  startupBannerImage: [],
  summary: '',
  productSummary: '',
  startupPitchDeck: [],
  investmentThesis: '',
  leadInvestorThesis: [],
  minInvestment: 1,
  dealLead: ''
};

const startupInfoValidation = Yup.object({
  startupName: Yup.string().required('Startup name is required'),
  entityName: Yup.string().required('Entity name is required'),
  registrationCountry: Yup.object().required('Country of registration is required'),
  startupLocationTokens: Yup.object().required(),
  startupStage: Yup.object().required('Stage of startup is required'),
  startupSectors: Yup.object().required(),
  businessType: Yup.object().required('Type of business is required'),
  startupExistence: Yup.boolean().isTrue(
    'As per SEBI regulations, LV AIF can only invest in a startup which has been incorporated less than 10 years ago'
  ),
  revenueCheck: Yup.boolean().isFalse(
    "As per SEBI regulations, LV AIF can only invest in a startup whose turnover doesn't exceed ₹ 100 Cr in any of the financial years since its incorporation"
  ),
  founderName: Yup.string().required('Founder name is required'),
  founderEmail: Yup.string().email('Invalid email').required('Founder email is required'),
  founderPhoneNumber: Yup.string()
    .trim()
    .test('test-is-valid-mobile', 'Please provide valid phone number', (value) => isValidMobileNumber(value))
    .required('Founder phone number is required'),
  startupProfileImage: Yup.array().min(1, 'This field is required').required('This field is required'),
  summary: Yup.string().required(),
  productSummary: Yup.string().required(),
  startupPitchDeck: Yup.array().min(1, 'This field is required').required('This field is required'),
  investmentThesis: Yup.string().when('dealLead', (dealLead: any, schema) =>
    dealLead === 'syndicate' ? schema.required('Investment thesis is required') : schema.notRequired()
  ),
  leadInvestorThesis: Yup.array().when('dealLead', (dealLead: any, schema) =>
    dealLead === 'syndicate'
      ? schema.min(1, 'This field is required').required('This field is required')
      : schema.notRequired()
  ),
  minInvestment: Yup.number()
    .min(1, 'Minimum investment should be equal or greater than 1')
    .required('Minimum investment is required'),
  dealLead: Yup.string().required('Deal lead is required')
});

const StartupInfoForm: FC<any> = ({ apiStatus, setApiStatus, setSelectedTab, setIsDraftMode, viewMode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const dealManager = new DealsManager(authContext);

  const [initialValues, setInitialValues] = useState<any>(initialValuesConstant);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState<any>([]);
  const [sectorOptions, setSectorOptions] = useState<any>([]);
  const [submittingStatus, setSubmittingStatus] = useState(false);
  const [savingStatus, setSavingStatus] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);

  const getLookupOptions = async (search: string, type: string) => {
    try {
      if (type === 'locations') setLoadingLocations(true);
      if (type === 'countries') setLoadingCountries(true);
      let result = await dealManager.getLookupData(search, type);
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id
      }));
      if (type === 'locations') setLocationOptions(result);
      if (type === 'countries') setCountryOptions(result);
    } catch (e: any) {
      toast.error(e.message || 'Error fetching lookup options');
    } finally {
      if (type === 'locations') setLoadingLocations(false);
      if (type === 'countries') setLoadingCountries(false);
    }
  };

  const getSectorOptions = async () => {
    try {
      let result = await dealManager.getSectors('vertical_sector');
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id
      }));
      setSectorOptions(result);
    } catch (e: any) {
      toast.error(e.message || 'Error fetching sector options');
    }
  };

  const getStartupFormData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const response = await dealManager.fetchStartupFormData(id);
      if (response) {
        setInitialValues(response);
        setIsDraftMode(response?.draftMode);
        if (![DealStatusEnum.SAVED, DealStatusEnum.CANCELLED].includes(response?.dealStatus) && !viewMode) {
          navigate(`/deals/${id}`);
        }
      }
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error(e.message || 'Error fetching startup form data');
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const submitStartupData = async (values: any) => {
    setSubmittingStatus(true);
    try {
      const response = await dealManager.createOrUpdateStartup(values, id);
      localStorage.setItem('create_startup_id', response?.startup_id);
      localStorage.setItem('create_startup_permalink', response?.permalink);
      setIsDraftMode(false);
      setSelectedTab(DealTabType.SCHEME_INFO);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setSubmittingStatus(false);
    }
  };

  const saveDraftStartupData = async (values: any) => {
    setSavingStatus(true);
    try {
      const response = await dealManager.createOrUpdateStartup(values, id, true);
      if (!id) {
        navigate(`/deals/edit/${response?.startup_id}`);
      }
      toast.success('Details saved successfully');
    } catch (e: any) {
      toast.error(e.message || 'Error saving draft startup data');
    } finally {
      setSavingStatus(false);
    }
  };

  useEffect(() => {
    getLookupOptions('', 'locations');
    getLookupOptions('', 'countries');
    getSectorOptions();
  }, []);

  useEffect(() => {
    if (id) {
      getStartupFormData();
    }
  }, [id]);

  if (apiStatus === API_STATUS.LOADING)
    return (
      <Box width="60%">
        <FormPlaceholder elementsConfig={formPlaceholderConfig} />
      </Box>
    );

  return (
    <Formik
      validationSchema={startupInfoValidation}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={submitStartupData}
      validateOnMount={!!id}
      initialTouched={!!id ? Object.keys(initialValues).reduce((acc, key) => ({ ...acc, [key]: true }), {}) : {}}
    >
      {(form) => {
        const { values, setFieldValue, handleSubmit, isValid, dirty, errors, submitForm, touched, setFieldTouched } =
          form;
        const allFieldsFilled = Object.keys(startupInfoValidation.fields).every((key) => values[key]);
        return (
          <Form>
            <Stack gap={2} width={'60%'} textAlign="left">
              <InputField
                label="Enter Startup Name"
                required
                fieldName="startupName"
                id="startupName"
                helperText="Brand Name e.g. PayTM"
                value={values?.startupName}
                error={!!errors?.startupName}
                disabled={viewMode}
              />
              <InputField
                label="What is the entity name as per govt records?"
                required
                fieldName="entityName"
                id="entityName"
                value={values?.entityName}
                error={!!errors?.entityName}
                helperText="e.g. name as per ROC records in India - One97 Communications Pvt Ltd"
                disabled={viewMode}
              />
              <Dropdown
                label="What is the country of Registration?"
                required
                fieldName="registrationCountry"
                id="registrationCountry"
                options={countryOptions}
                value={values?.registrationCountry}
                error={!!errors?.registrationCountry}
                disabled={viewMode}
                primary={!viewMode}
                loading={loadingCountries}
              />
              <Dropdown
                label="Which city the Startup is operating from?"
                fieldName="startupLocationTokens"
                id="startupLocationTokens"
                options={locationOptions}
                value={values?.startupLocationTokens}
                error={!!errors?.startupLocationTokens}
                disabled={viewMode}
                required
                primary={!viewMode}
                loading={loadingLocations}
              />
              <Dropdown
                label="Which stage is the startup currently in?"
                required
                fieldName="startupStage"
                id="startupStage"
                options={StartupStageOptions}
                value={values?.startupStage}
                error={!!errors?.startupStage}
                disabled={viewMode}
                primary={!viewMode}
              />
              <Dropdown
                label="Which sectors the Startup operates in?"
                fieldName="startupSectors"
                id="startupSectors"
                options={sectorOptions}
                value={values?.startupSectors}
                error={!!errors?.startupSectors}
                disabled={viewMode}
                required
                primary={!viewMode}
              />
              <Dropdown
                label="What is the type of business?"
                required
                fieldName="businessType"
                id="businessType"
                options={BusinessTypeOptions}
                value={values?.businessType}
                error={!!errors?.businessType}
                disabled={viewMode}
                primary={!viewMode}
              />
              <RadioButtonSelect
                fieldName="dealLead"
                id="dealLead"
                value={values?.dealLead}
                error={!!errors?.dealLead}
                required
                formLabel={`Who is leading the deal?`}
                options={[
                  { name: 'Founder', value: 'founder', disabled: viewMode },
                  { name: 'Syndicate', value: 'syndicate', disabled: viewMode }
                ]}
                helperText="Note: as per SEBI regulations, LV AIF can only invest in a startup which has been incorporated less than 10 years ago."
              />
              <RadioButtonSelect
                fieldName="startupExistence"
                id="startupExistence"
                value={values?.startupExistence}
                error={!!errors?.startupExistence}
                required
                formLabel={`Is the Startup less than 10 years old?`}
                options={[
                  { name: 'Yes', value: 'true', disabled: viewMode },
                  { name: 'No', value: 'false', disabled: viewMode }
                ]}
                helperText="Note: as per SEBI regulations, LV AIF can only invest in a startup which has been incorporated less than 10 years ago."
                disabled={viewMode}
              />
              <RadioButtonSelect
                fieldName="revenueCheck"
                id="revenueCheck"
                value={values?.revenueCheck}
                error={!!errors?.revenueCheck}
                required
                formLabel="Is the Startup revenue less than ₹ 100 Cr in any 12 months in its history?"
                options={[
                  { name: 'Yes', value: 'false', disabled: viewMode },
                  { name: 'No', value: 'true', disabled: viewMode }
                ]}
                helperText="Note: as per SEBI regulations, LV AIF can only invest in a startup whose turnover doesn't exceed ₹ 100 Cr in any of the
            financial years since its incorporation."
                disabled={viewMode}
              />
              <Divider />
              <FormLabel>Enter details of one founder</FormLabel>
              <InputField
                label="Enter Founder Name"
                required
                fieldName="founderName"
                id="founderName"
                value={values?.founderName}
                error={!!errors?.founderName}
                disabled={viewMode}
              />{' '}
              <InputField
                label="Enter Founder Email"
                required
                fieldName="founderEmail"
                id="founderEmail"
                value={values?.founderEmail}
                error={!!errors?.founderEmail}
                disabled={viewMode}
              />{' '}
              <Field name="founderPhoneNumber">
                {({ form }: any) => (
                  <PhoneNumber
                    form={form}
                    label="Enter Founder Phone Number"
                    id="founderPhoneNumber"
                    required
                    error={!!errors?.founderPhoneNumber}
                    value={values?.founderPhoneNumber}
                    onChange={(number: any, country: any, e: any) => {
                      setFieldValue('founderPhoneNumber', `+${country}`, true);
                    }}
                    disabled={viewMode}
                  />
                )}
              </Field>
              <Divider />
              <FileUpload
                title="Upload Startup Logo"
                selectedFile={(file: any) => setFieldValue('startupProfileImage', file)}
                value={values?.startupProfileImage}
                error={!!errors?.startupProfileImage && !!touched?.startupProfileImage}
                label="Upload Startup Logo"
                helperText="Upload the logo in jpg or png format."
                accept={{
                  'image/svg': ['.png', '.jpg', '.jpeg']
                }}
                width="auto"
                disabled={viewMode}
                required
                hideDocumentVault
              />
              <FileUpload
                title="Upload Startup Banner Image"
                selectedFile={(file: any) => setFieldValue('startupBannerImage', file)}
                value={values?.startupBannerImage}
                error={!!errors?.startupBannerImage && !!touched?.startupBannerImage}
                accept={{
                  'image/svg': ['.png', '.jpg', '.jpeg']
                }}
                label="Upload Startup Banner Image"
                helperText="Banner image makes the startup profile look good to investors.
            Upload image in JPG or PNG format, seize of 1200x800 px recommended"
                width="auto"
                disabled={viewMode}
                hideDocumentVault
              />
              <InputField
                fieldName="summary"
                id="summary"
                value={values?.summary}
                error={!!errors?.summary}
                label="Enter the Elevator Pitch for the Startup"
                helperText="Enter the Elevator Pitch for the Startup. max 200 characters"
                multiline
                minRows={3}
                disabled={viewMode}
                required
                maxLength={200}
              />
              <InputField
                fieldName="productSummary"
                id="productSummary"
                value={values?.productSummary}
                error={!!errors?.productSummary}
                label="Enter the Startup Product/Service Summary"
                helperText="Enter the Startup Product/Service Summary. max 100 characters"
                multiline
                minRows={2}
                disabled={viewMode}
                required
                maxLength={100}
              />
              <FileUpload
                title="Upload Startup Pitch Deck"
                selectedFile={(file: any) => setFieldValue('startupPitchDeck', file)}
                value={values?.startupPitchDeck}
                error={!!errors?.startupPitchDeck && !!touched?.startupPitchDeck}
                label="Upload Startup Pitch Deck"
                helperText="Upload the pdf file (max size 20 MB)"
                width="auto"
                disabled={viewMode}
                required
                accept={{
                  'text/pdf': ['.pdf']
                }}
                hideDocumentVault
              />
              {values?.dealLead === 'syndicate' && (
                <>
                  <InputField
                    fieldName="investmentThesis"
                    id="investmentThesis"
                    value={values?.investmentThesis}
                    error={!!errors?.investmentThesis}
                    label="Why are you (syndicate lead) investing in this Startup?"
                    multiline
                    minRows={5}
                    disabled={viewMode}
                    required
                  />
                  <FileUpload
                    title="Upload your (syndicate lead) thesis document"
                    selectedFile={(file: any) => setFieldValue('leadInvestorThesis', file)}
                    onChange={(file: any) => setFieldTouched('leadInvestorThesis', true)}
                    value={values?.leadInvestorThesis}
                    error={!!errors?.leadInvestorThesis && !!touched?.leadInvestorThesis}
                    label="Upload your (syndicate lead) thesis document"
                    helperText="Upload the pdf file (max size 20 MB)"
                    width="auto"
                    disabled={viewMode}
                    required
                    accept={{
                      'text/pdf': ['.pdf']
                    }}
                    hideDocumentVault
                  />
                </>
              )}
              <TimePeriodDropdown
                coreDropdownProps={{
                  label: '',
                  options: [{ label: 'Lakhs', value: 100000 }],
                  disabled: true,
                  defaultValue: { label: 'Lakhs', value: 100000 },
                  value: values.minInvestmentUnit,
                  id: 'minInvestmentUnit',
                  name: 'minInvestmentUnit',
                  hideEndAdornment: true
                }}
                customTextFieldProps={{
                  id: 'minInvestment',
                  name: 'minInvestment',

                  type: 'number',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography color={getColor('primary', 700)}>₹</Typography>
                      </InputAdornment>
                    )
                  },
                  value: values?.minInvestment,
                  label: 'What is the minimum commitment per investor?',
                  required: true,
                  disabled: viewMode
                }}
                name="minInvestment"
                id="minInvestment"
                dropdownWidth="100px"
                error={errors?.minInvestment}
                touched={touched?.minInvestment}
                helperText="Note: this is the default value for the deal. Once you launch the deal, you can always modify it on a per investor basis."
              />
              <br />
              {!viewMode && (
                <Stack direction="row" gap={2} justifyContent="end">
                  <ActionButton
                    disabled={viewMode || (!dirty && !id) || savingStatus}
                    isLoading={savingStatus}
                    onClick={() => {
                      saveDraftStartupData(values);
                    }}
                  >
                    Save as Draft
                  </ActionButton>

                  <ActionButton
                    disabled={viewMode || (!id ? !(isValid && dirty) : !isValid) || submittingStatus}
                    isLoading={submittingStatus}
                    onClick={(values: any) => {
                      if (isValid) handleSubmit(values);
                    }}
                  >
                    Next
                  </ActionButton>
                </Stack>
              )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StartupInfoForm;
