import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import { getColor } from '../../colors';

export type OutlinedButtonProps = {
  /**
   * Id of button
   */
  id?: string;
  /**
   * Renders any ReactNode inside itself
   */
  children?: ReactNode;
  /**
   * enable/disable the button
   */
  disabled?: boolean;
  /**
   * onClick handler function
   */
  onClick?: Function;
  /**
   * custom styling of the button. Accepts styling for MUI sx prop
   */
  customStyles?: object;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  /**
   * ref to the button
   */
  buttonRef?: any;
};

export function OutlinedButton({
  children,
  disabled = false,
  onClick,
  customStyles = {},
  hidden = false,
  id,
  buttonRef = null
}: OutlinedButtonProps) {
  const handleChange = () => {
    onClick && onClick();
  };

  if (hidden) {
    return <div />;
  }

  return (
    <Button
      ref={buttonRef}
      id={id}
      data-testid="outlined-btn"
      disabled={disabled}
      variant="outlined"
      onClick={handleChange}
      sx={{
        color: getColor('action_button', 300),
        borderColor: getColor('action_button', 300),
        borderRadius: '4px',
        textAlign: 'center',
        letterSpacing: '1px',
        '&:hover': {
          borderColor: getColor('action_button', 400),
          boxShadow: 'none'
        },
        ...customStyles
      }}
    >
      {children}
    </Button>
  );
}
