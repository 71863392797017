import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { useNavigate } from 'react-router-dom';
import { SectionHeader } from '../../../ui-library/components/header/section-header';

const NoLiveDeal = () => {
  const navigate = useNavigate();
  const navigateToLaunch = () => {
    navigate('/deals/create');
  };

  return (
    <Box mt={3}>
      <Stack direction="row" justifyContent="space-between" mb={1} alignItems="center">
        <SectionHeader>Live Deals</SectionHeader>
      </Stack>
      <Box className="card_border text-left" p={'5rem 1.875rem'} textAlign="center">
        <Typography variant="paragraph_02_bold" mb={2}>
          You have no live deals right now.
        </Typography>
        <ActionButton onClick={navigateToLaunch}>Launch New Deal</ActionButton>
      </Box>
    </Box>
  );
};

export default NoLiveDeal;
