import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import DealsService from '../service/DealsService';
import {
  BusinessTypeOptions,
  CountryRegTypeOptions,
  SecurityTypeOptions,
  StartupStageOptions
} from '../constants/DealsConstant';
import moment from 'moment';

export default class DealsManager {
  private userId: string;
  private permalink: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
    this.permalink = authContext?.authState?.permalink as string;
  }

  async getAllDeals() {
    try {
      const response: any = await DealsService.getAllDeals(this.userId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getDealDetail(dealId?: string) {
    try {
      const response: any = await DealsService.getDealDetail(this.userId, dealId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getBackersForDeal(syndicateDealId?: string) {
    try {
      const response: any = await DealsService.getBackersForDeal(this.userId, syndicateDealId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getUninvitedBackersForDeal(startupId?: string) {
    try {
      const response: any = await DealsService.getUninvitedBackersForDeal(this.userId, startupId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async inviteBackersForDeal(startupId: string, medium: string, backerList: any, isTest?: boolean) {
    try {
      const formData = {
        startup_id: startupId,
        medium,
        backer_list: backerList,
        is_test: isTest
      };
      const response: any = await DealsService.inviteBackersForDeal(this.userId, formData);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async createOrUpdateStartup(data?: any, startupId?: string, isSavingInDraft: boolean = false) {
    try {
      const formData = new FormData();
      formData.append('syndicate_id', this.userId);
      if (startupId) formData.append('startup_id', startupId);
      if (data?.businessType) formData.append('business_type', data?.businessType?.value);
      if (data?.entityName) formData.append('entity_name', data?.entityName);
      if (data?.investmentThesis) formData.append('investment_thesis', data?.investmentThesis);
      if (data?.leadInvestorThesis?.length && data?.leadInvestorThesis?.[0]?.name)
        formData.append('lead_investor_thesis', data?.leadInvestorThesis[0]);
      if (data?.minInvestment) formData.append('min_investment', data?.minInvestment);
      if (data?.productSummary) formData.append('product_summary', data?.productSummary);
      if (data?.startupSectors) formData.append('sector_tokens', data?.startupSectors?.value ?? data?.startupSectors);
      if (data?.startupBannerImage?.length && data?.startupBannerImage?.[0]?.name)
        formData.append('startup_banner_image', data?.startupBannerImage[0]);
      if (data?.startupExistence?.length) formData.append('startup_existence', data?.startupExistence);
      if (data?.revenueCheck) formData.append('revenue_check', data?.revenueCheck);
      if (data?.startupLocationTokens)
        formData.append('startup_location_tokens', data?.startupLocationTokens?.value ?? data?.startupLocationTokens);
      if (data?.startupName) formData.append('startup_name', data?.startupName);
      if (data?.startupName) formData.append('permalink', `${data?.startupName}-1`);
      if (data?.startupPitchDeck?.length && data?.startupPitchDeck?.[0]?.name)
        formData.append('startup_pitch_deck', data?.startupPitchDeck[0]);
      if (data?.startupProfileImage?.length && data?.startupProfileImage?.[0]?.name)
        formData.append('startup_profile_image', data?.startupProfileImage[0]);
      if (data?.registrationCountry) formData.append('startup_registration_country', data?.registrationCountry?.value);
      if (data?.startupStage) formData.append('startup_stage', data?.startupStage?.value);
      if (data?.summary) formData.append('summary', data?.summary);
      if (data?.founderName) formData.append('founder_name', data?.founderName);
      if (data?.founderEmail) formData.append('founder_email', data?.founderEmail);
      if (data?.founderPhoneNumber) formData.append('founder_number', data?.founderPhoneNumber);
      if (data?.dealLead) formData.append('deal_lead', data?.dealLead);

      const response: any = await DealsService.createOrUpdateStartup(isSavingInDraft, this.userId, formData);

      return response?.data;
    } catch (error: any) {
      toast.error(error?.detail);
      throw error;
    }
  }

  handleNoDaysToCommit(values: any) {
    const dateObjj = new Date();
    dateObjj.setDate(dateObjj.getDate() + (values.commit_time_window || 0) + 7);
    return dateObjj.toISOString().substring(0, 10);
  }

  getDealStatus(dealData: any) {
    const statusData: any = {};
    const { update_logs = [] } = dealData;
    if (update_logs?.length) {
      const lastUpdatedLog = update_logs.findLast((log: any) => log?.type === 'updated');
      const submittedLog = update_logs.findLast((log: any) => log?.type === 'submitted');
      const reSubmittedLog = update_logs.findLast((log: any) => log?.type === 're_submitted');
      const rejectedLog = update_logs.findLast(
        (log: any, index: any) => log?.type === 'rejected' && index === update_logs.length - 1
      );
      const approvedLog = update_logs.findLast((log: any) => log?.type === 'accepted');
      const awaitingLog = update_logs.find((log: any) => ['submitted', 're_submitted'].includes(log?.type));

      if (lastUpdatedLog) {
        statusData.step1Text = `last updated: ${moment(lastUpdatedLog.created_at).format('MMM DD, YYYY')}`;
      }

      if (submittedLog) {
        statusData.step1Text = `submitted to LV for review: ${moment(submittedLog.created_at).format('MMM DD, YYYY')}`;
      }

      if (reSubmittedLog) {
        statusData.step1Text = `submitted to LV for review: ${moment(reSubmittedLog.created_at).format(
          'MMM DD, YYYY'
        )}`;
      }

      if (awaitingLog) {
        statusData.step2Text = `awaiting approval from LV team`;
        statusData.awaitingApproval = true;
      }
      if (approvedLog) {
        statusData.step2Text = `approved by LV team: ${moment(approvedLog.created_at).format('MMM DD, YYYY')}`;
      }
      if (rejectedLog) {
        statusData.step2Text = `rejected by LV team: ${moment(rejectedLog.created_at).format('MMM DD, YYYY')}`;
        statusData.awaitingApproval = false;
      }
    }

    return statusData;
  }

  async updateSchemeData(data?: any, isSavingInDraft: boolean = false) {
    try {
      const formData = new FormData();
      if (data?.startup_id) formData.append('startup_id', data?.startup_id);
      if (data?.startup_name) formData.append('startup_name', data?.startup_name);
      if (data?.current_action) formData.append('current_action', data?.current_action);
      if (data?.reg_startup_name) formData.append('reg_startup_name', data?.reg_startup_name);
      if (data?.hurdle_rate) formData.append('hurdle_rate', data?.hurdle_rate);
      if (data?.round_type) formData.append('round_type', data?.round_type?.value);
      if (data?.total_ask) formData.append('total_ask', data?.total_ask);
      if (data?.commitment_via_lv) formData.append('commitment_via_lv', data?.commitment_via_lv);
      if (data?.pre_money_valuation) formData.append('pre_money_valuation', data?.pre_money_valuation);
      if (data?.pre_money_valuation_currency) formData.append('pre_money_valuation_currency', '1');
      if (data?.fund_carry) formData.append('fund_carry', data?.fund_carry);
      if (data?.total_lead_carry) formData.append('total_lead_carry', data?.total_lead_carry);
      formData.append('last_date', this.handleNoDaysToCommit(data));
      if (data?.commit_time_window) formData.append('commit_time_window', data?.commit_time_window);
      if (data?.securities_description) formData.append('securities_description', data?.securities_description);
      if (data?.conversion_terms) formData.append('conversion_terms', data?.conversion_terms);
      if (data?.startup_service) formData.append('startup_service', data?.startup_service);
      if (data?.right_under_negotiations) formData.append('right_under_negotiations', data?.right_under_negotiations);
      if (data?.share_price_with_text) formData.append('share_price_with_text', data?.share_price_with_text);
      if (data?.startup_service) formData.append('startup_service', data?.startup_service);
      if (data?.startup_transaction) formData.append('startup_transaction', data?.startup_transaction);
      if (data?.revenue_check) formData.append('revenue_check', data?.revenue_check);
      if (data?.dpiit) formData.append('dpiit', data?.dpiit);
      if (data?.draft_agreement_file?.length && data?.draft_agreement_file?.[0]?.name)
        formData.append('draft_agreement_file', data?.draft_agreement_file[0]);
      if (data?.send_auto_drawdown) formData.append('send_auto_drawdown', data?.send_auto_drawdown);
      if (data?.lv_transaction_fee) formData.append('lv_transaction_fee', data?.lv_transaction_fee);
      if (data?.lv_team_comments) formData.append('lv_team_comments', data?.lv_team_comments);
      if (data?.lead_investor_list) formData.append('lead_investor_list', JSON.stringify(data?.lead_investor_list));
      formData.append('incomplete', 'true');
      const response: any = await DealsService.updateSchemeData(this.userId, formData, isSavingInDraft);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.error);
      throw error;
    }
  }

  async submitForApproval(startupId?: string) {
    try {
      const data = { startup_id: startupId };
      const response: any = await DealsService.submitForApproval(this.userId, data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async fetchStartupFormData(startupId?: string) {
    try {
      let response: any = await DealsService.fetchStartupFormData(this.userId, startupId);
      response = response?.data?.startup;
      const {
        startup_name = '',
        entity_name = '',
        investment_thesis = '',
        lead_investing_thesis = null,
        min_investment = '',
        product_summary = '',
        revenue_check = false,

        sector_tokens = null,
        startup_banner_image = null,
        startup_existence = false,
        startup_location_tokens = null,
        startup_pitch_deck = null,
        startup_profile_image = null,
        startup_registration_country = null,
        startup_stage = null,
        summary = '',
        business_type = null,
        draft_mode,
        founder_deatils,
        deal_lead = null,
        deal_status
      } = response ?? {};
      let result = {
        startupName: startup_name,
        entityName: entity_name,
        registrationCountry: startup_registration_country ?? null,
        startupLocationTokens: startup_location_tokens?.length
          ? { label: startup_location_tokens[0]?.text, value: startup_location_tokens[0]?.id }
          : null,
        startupStage: StartupStageOptions?.find((item: any) => item?.value === startup_stage) ?? null,
        startupSectors: sector_tokens?.[0] ?? {},

        businessType: BusinessTypeOptions?.find((item: any) => item?.value === business_type) ?? null,
        startupExistence: startup_existence,
        revenueCheck: revenue_check,
        summary,
        productSummary: product_summary,
        investmentThesis: investment_thesis,
        minInvestment: min_investment,
        founderName: founder_deatils?.length ? founder_deatils[0]?.name : '',
        founderEmail: founder_deatils?.length ? founder_deatils[0]?.founder_email : '',
        founderPhoneNumber: founder_deatils?.length ? founder_deatils[0]?.founder_number : '',
        startupProfileImage: startup_profile_image?.key ? [{ path: startup_profile_image?.key }] : [],
        startupBannerImage: startup_banner_image?.key ? [{ path: startup_banner_image?.key }] : [],
        leadInvestorThesis: lead_investing_thesis?.key ? [{ path: lead_investing_thesis?.key }] : [],
        startupPitchDeck: startup_pitch_deck?.key ? [{ path: startup_pitch_deck?.key }] : [],
        draftMode: draft_mode,
        dealLead: deal_lead,
        dealStatus: deal_status
      };
      return result;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async fetchSchemeInfoFormData(startupId?: string) {
    try {
      let response: any = await DealsService.fetchSchemeInfoFormData(this.userId, startupId);
      let { specific_data } = response?.data?.data;
      let result = {
        ...specific_data,
        round_type: SecurityTypeOptions?.find((item: any) => item?.value == specific_data?.round_type) ?? null,
        commit_time_window: specific_data?.commit_time_window ?? 1,
        last_date_to_consent: moment(specific_data?.last_date_to_consent).format('DD MMM YYYY'),
        draft_agreement_file: specific_data?.draft_agreement_file?.key
          ? [{ path: specific_data?.draft_agreement_file?.key }]
          : []
      };

      return result;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getLookupData(search: string, type: string, user_search_private_hashtag?: string, syndicateId?: string) {
    try {
      let result = await DealsService.getLookupData(search, type, user_search_private_hashtag, syndicateId);
      return result?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getSectors(type: any) {
    try {
      let result = await DealsService.getSectors(type);
      return result?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async launchSyndicateDeal({ syndicatePermalinkdata = null, data }: any) {
    try {
      syndicatePermalinkdata = syndicatePermalinkdata ?? localStorage.getItem('syndicate_permalink');
      const response: any = await DealsService.launchSyndicateDeal(syndicatePermalinkdata, data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async manageCommitement(startup_id: string, backer_obj: any, values: any) {
    try {
      const data = {
        backer_record: [
          {
            ...backer_obj,
            investor_id: backer_obj?.inv_id ?? backer_obj?.investor_id,
            proposed_amount: values?.proposed_amount,
            condition: values?.condition?.value
          }
        ]
      };
      const response: any = await DealsService.manageCommitment(this.permalink, startup_id, data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async sendDrawdown(investor_commitment_id: string, transfer_by_Date: any) {
    try {
      const data = {
        investor_commitment_id,
        transfer_by_Date
      };
      const response: any = await DealsService.sendDrawdown(data);
      return response?.data;
    } catch (error: any) {
      toast.error(error);
      throw error;
    }
  }
}
