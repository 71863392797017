import { Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { AuthContext } from '../../../contexts/authContext';
import DealsManager from '../manager/DealsManager';
import { toast } from 'react-toastify';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import TableLogo from '../../common/components/TableLogo';
import { API_STATUS } from '../../common/constants/CommonConstants';
import { CommunicationLogConstant } from '../constants/CommunicationLogConstant';

const CommunicationLogs = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { communication_arr, startupData } = state ?? {};
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);

  const authContext = useContext(AuthContext);
  const dealsManager = new DealsManager(authContext);

  const [communicationLogs, setCommunicationLogs] = useState(communication_arr);
  const [startupDetail, setStartupDetail] = useState(startupData);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const getBackersOfDealData = async (syndicate_deal_id: string) => {
    try {
      const result = await dealsManager.getBackersForDeal(syndicate_deal_id);
      if (result) {
        setCommunicationLogs(result?.communication_arr ?? []);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const getDealData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const result = await dealsManager.getDealDetail(id);

      if (result?.startups_response) {
        setStartupDetail(result?.startups_response);
      }

      if (result?.startups_response?.syndicate_deal_id) {
        await getBackersOfDealData(result?.startups_response?.syndicate_deal_id);
        setApiStatus(API_STATUS.SUCCESS);
      }
    } catch (error: any) {
      toast.error(error);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (!communication_arr) {
      getDealData();
    } else {
      setCommunicationLogs(communication_arr);
      setStartupDetail(startupData);
    }
  }, [location]);

  const columnsData = [
    {
      field: 'user_name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 350,
      headerName: 'NAME'
    },
    {
      field: 'campaign_date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'CAMPAIGN DATE',
      type: 'date',
      renderCell: (params: any) => params?.row?.formatted_campaign_date,
      valueGetter: (params: any) => params?.row?.campaign_date
    },
    {
      field: 'campaign_type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 100,
      headerName: 'CAMPAIGN TYPE'
    },
    {
      field: 'type_of_communication',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 170,
      headerName: "BACKER'S DEAL PROGRESS",
      renderCell: (params: any) => CommunicationLogConstant?.[params?.row?.type_of_communication] ?? ''
    }
  ];

  const CommunicationLogBreadcrumbData: any = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: startupDetail?.name,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Communication Logs',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={CommunicationLogBreadcrumbData.steps}
          breadcrumbSeparator={CommunicationLogBreadcrumbData.breadcrumbSeparator}
          pageHeader={CommunicationLogBreadcrumbData.pageHeader}
          stepKey={CommunicationLogBreadcrumbData.stepKey}
        />
        <Box gap={1} display="flex" alignItems="center">
          <TableLogo url={startupDetail?.logo} />
          <SectionHeader>{startupDetail?.name}</SectionHeader>
        </Box>
      </Stack>

      {apiStatus === API_STATUS.LOADING ? (
        <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={communicationLogs}
          columns={columnsData}
          leftPinnedColumns={['name']}
          defaultSortColumn={{ field: 'campaign_date', sort: 'desc' }}
          activeFilters={filters}
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}
    </Box>
  );
};

export default CommunicationLogs;
