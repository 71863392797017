import React, { FC, useEffect, useState } from 'react';
import { CommonModal } from '../../../ui-library/components/modals/common-modal';
import { FormContainer } from '../../../ui-library/components/form-elements/FormContainer';
import { Formik } from 'formik';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { InputField } from '../../../ui-library/components/form-elements/CustomTextField';
import { Dropdown } from '../../../ui-library/components/dropdowns/core-dropdown';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { ManageCommitOptions } from '../constants/IndividualDealConstant';
import { TimePeriodDropdown } from '../../../ui-library/components/dropdowns/time-period-dropdown';
import { getColor } from '../../../ui-library/colors';

const ManageCommitModal: FC<any> = ({ openModal, handleClose, handleSubmit, apiLoading = false }) => {
  const [initialValues, setInitialValues] = useState<any>({ name: '', email: '', condition: null, proposed_amount: 0 });

  useEffect(() => {
    setInitialValues({ ...initialValues, ...openModal });
  }, [openModal]);

  return (
    <CommonModal submitText="continue" isOpen={!!openModal} handleClose={handleClose} title="Manage Commit" hideButtons>
      <FormContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
          {(form) => {
            return (
              <Stack gap={2}>
                <InputField disabled fieldName="name" id="name" value={form?.values.name} label="Investor Name" />
                <InputField
                  disabled
                  fieldName="email"
                  id="email"
                  value={form?.values.email}
                  label="Email"
                  type="email"
                />
                <Dropdown
                  label="How would you like to configure Commit from this investor?"
                  fieldName="condition"
                  id="condition"
                  value={form?.values.condition}
                  options={ManageCommitOptions}
                />

                <TimePeriodDropdown
                  coreDropdownProps={{
                    label: '',
                    options: [
                      { label: 'Lakhs', value: 100000 },
                      { label: 'Crores', value: 10000000 }
                    ],
                    disabled: true,
                    defaultValue: { label: 'Lakhs', value: 100000 },
                    value: form?.values.proposed_amount_unit,
                    id: 'proposed_amount_unit',
                    name: 'proposed_amount_unit',
                    hideEndAdornment: true
                  }}
                  customTextFieldProps={{
                    id: 'proposed_amount',
                    name: 'proposed_amount',
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color={getColor('primary', 700)}>₹</Typography>
                        </InputAdornment>
                      )
                    },
                    value: form?.values.proposed_amount,
                    label: 'Enter the Amount'
                  }}
                  dropdownWidth="120px"
                  error={form?.errors?.proposed_amount}
                  touched={form?.touched?.proposed_amount}
                />

                <br />
                <Stack direction="row" gap={1} justifyContent="end">
                  <OutlinedButton onClick={handleClose}>Cancel</OutlinedButton>
                  <ActionButton onClick={form?.handleSubmit} disabled={apiLoading} isLoading={apiLoading}>
                    Save
                  </ActionButton>
                </Stack>
              </Stack>
            );
          }}
        </Formik>
      </FormContainer>
    </CommonModal>
  );
};

export default ManageCommitModal;
