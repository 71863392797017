import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/authContext';
import MainLoader from '../../common/loaders/MainLoader';
import AuthManager from '../manager/AuthManager';

const UserLoginRedirection = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [updatedAccessToken, setAccessToken] = useState('');
  const [idTokens, setIdToken] = useState('');
  const { getAccessTokenSilently, isAuthenticated = false, getIdTokenClaims, isLoading = true, logout } = useAuth0();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const authManager = new AuthManager(authContext);

  const { setUserSession } = authContext;
  const getAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_GLASSBOARD_AUTH0_AUDIENCE
        }
      });
      const idToken: any = await getIdTokenClaims();
      setIdToken(idToken);
      setAccessToken(accessToken);
    } catch (error: any) {
      toast.error(error.message);
      window.location.href = '/';
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getAccessToken();
    }
  }, [isLoading]);

  const redirectUserTo = async () => {
    // TODO: Add posthog tracking
    try {
      if (isAuthenticated) {
        localStorage.setItem('token', updatedAccessToken);

        const userDetails = await authManager.getSyndicateUserDetails();
        setUserSession(
          updatedAccessToken,
          userDetails.user,
          userDetails.syndicate_id,
          userDetails.syndicate_type,
          userDetails.syndicate_permalink
        );
        navigate('/');
      }
    } catch (error: any) {
      let errorMessage = '';

      if (error?.response?.status === 404) {
        errorMessage = 'Email not registered as LV Syndicate Admin. Call your LV contact.';
      } else if (error?.response?.status === 500) {
        errorMessage = 'Something went wrong. Please contact contactus@letsventure.com';
      } else {
        errorMessage = 'Username or Password is wrong';
      }
      toast.error(errorMessage);
      if (isAuthenticated) {
        setTimeout(() => {
          logout({
            logoutParams: {
              returnTo: `${window.location.origin}`
            }
          });
          localStorage.clear();
        }, 3000);
      }
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (updatedAccessToken) {
      redirectUserTo();
    }
  }, [updatedAccessToken, isAuthenticated]);
  if (isPageLoading) {
    return <MainLoader />;
  }
  return '';
};

export default UserLoginRedirection;
