import { uniqueId } from 'lodash';
import React, { FC } from 'react';

const TableLogo: FC<{ url: string; id?: string }> = (props) => {
  const { url, id = uniqueId() } = props;
  const fallback =
    'https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg';

  const handleError = () => {
    let doc = document.getElementById(id) as HTMLImageElement;
    if (doc) {
      doc.src = fallback;
    }
  };
  return (
    <img
      style={{ height: 30, width: 30, objectFit: 'contain', aspectRatio: 1 }}
      id={id}
      src={url ?? fallback}
      onError={handleError}
    />
  );
};

export default TableLogo;
