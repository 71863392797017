import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';

const SyndicateDetailSkeleton = () => {
  const cardOptions = [
    {
      label: 'Syndicate Name',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Joined on LetsVenture',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Primary Admin',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'cc emails',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: 'Allow backers to access public Deals',
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    }
  ];

  return (
    <Box className="card_border text-left" mb={3}>
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default SyndicateDetailSkeleton;
