const BASE_URL = 'https://dppsg1pijs5gq.cloudfront.net/';
const DIY_IMAGES_URL = `${BASE_URL}diy/images/`;

export const DIY_LOGIN_SCREEN_IMAGE = `${DIY_IMAGES_URL}diy-login.png`;
export const LETSVENTURE_COLOUR_ICON = `${DIY_IMAGES_URL}Letsventure-colour-logo.svg`;
export const SYNDICATE_LOGO = `${DIY_IMAGES_URL}Syndicate_logo.png`;
export const SYNICATE_LOGO_COLOUR = `${DIY_IMAGES_URL}Syndicate_logo_colour.svg`;
export const PAGE_NOT_FOUND = `${DIY_IMAGES_URL}page-not-found.svg`;

export const MUI_X_PRO_LICENSE_KEY =
  'cef8c03fe7f22fac91039cafa84c2f14T1JERVI6NDMwNzgsRVhQSVJZPTE2ODMyNzcwNzAwMDAsS0VZVkVSU0lPTj0x';

export const API_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const USER_ROLES = {
  ADMIN: 'ADMIN'
};
