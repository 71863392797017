import { Box, Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { OptionWithEmployeesStatsticCard } from '../../../ui-library/components/card/option-with-employees-statstic-card';
import LiveDealSkeleton from '../../deals/skeletons/LiveDealSkeleton';

const DashboardSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title={<Skeleton variant="text" width={100} />}
            valueComp={<Skeleton variant="text" width={50} />}
            width="250px"
            height="94px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title={<Skeleton variant="text" width={100} />}
            valueComp={<Skeleton variant="text" width={50} />}
            height="94px"
            width="250px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title={<Skeleton variant="text" width={100} />}
            valueComp={<Skeleton variant="text" width={50} />}
            height="94px"
            width="250px"
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Skeleton variant="text" width={100} />

          <Skeleton variant="text" width={160} height={60} />
        </Stack>
        <LiveDealSkeleton />
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;
