import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import SettingService from '../service/SettingService';
import BackersService from '../../backers/service/BackersService';

export default class SettingManager {
  private userId: string;
  private permalink: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
    this.permalink = authContext?.authState?.permalink as string;
  }

  async fetchSyndicateAdmins() {
    try {
      const response: any = await SettingService.fetchSyndicateAdmins(this.userId);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async inviteAdminMember(data: any) {
    try {
      const response: any = await BackersService.inviteMemeber(this.userId, 'admin', 'invite', data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async removeAdminMember(data: any) {
    try {
      const response: any = await BackersService.inviteMemeber(this.userId, 'admin', 'deactivate', data);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async checkBackerAccess() {
    try {
      const response: any = await SettingService.checkBackerAccess(this.permalink);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async editSyndicateDetail(data: any) {
    try {
      const { syndicateName, primaryAdminName, primaryAdminEmail, accessCheck, ccEmails } = data ?? {};
      const formattedValues = {
        syndicate_name: syndicateName,
        primary_admin_name: primaryAdminName,
        // primary_admin_email: primaryAdminEmail,
        access_check: accessCheck,
        ccEmails
      };
      const response: any = await SettingService.editSyndicateDetail(this.userId, formattedValues);
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
