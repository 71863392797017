import requestManager from '../../../api/requestManager';

const fetchSyndicateAdmins = (syndicateId: string) =>
  requestManager.apiGet(`/v1/syndicates/fetch_syndicate_members/?id=${syndicateId}`);

const checkBackerAccess = (permalink: string) =>
  requestManager.apiGet(`/v1/syndicates/check_backer_access/?permalink=${permalink}`);

const editSyndicateDetail = (syndicateId: string, data: any) =>
  requestManager.apiPost(`/v1/syndicates/update_syndicate_settings/?id=${syndicateId}`, data);

export default { fetchSyndicateAdmins, checkBackerAccess, editSyndicateDetail };
