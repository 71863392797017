import { makeStyles } from '@mui/styles';
import { getColor } from '../../../colors';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-outlined': {
      color: '#888888',
      transform: 'translate(14px, 10px) scale(0.93)',
      fontFamily: 'Work Sans'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: getColor('primary', 700),
      transform: 'translate(12px, -6px) scale(0.65)'
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: getColor('primary', 700)
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColor('primary', 700)
    },
    '& .MuiFormControl-root': {
      border: '1px solid #DEDEDE'
    },
    '& .MuiOutlinedInput-root': {
      padding: '5px',
      fontSize: 14,
      '& fieldset': {
        border: `1px solid ${getColor('extra', 2000)}`
      },
      '&:hover fieldset': {
        borderColor: getColor('primary', 700)
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${getColor('primary', 700)}`
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px 9px',
      fontFamily: 'Work Sans',
      borderRight: `1px solid ${getColor('extra', 600)}`
    },
    '& textarea': {
      '&::placeholder': {
        color: getColor('extra', 600),
        fontWeight: 400,
        opacity: '1'
      }
    }
  },
  hideInputArea: {
    '& .MuiOutlinedInput-input': {
      display: 'none'
    }
  },
  dialogContent: {
    '& .MuiTabs-root': { height: 44 },
    '& .MuiBox-root': { padding: '12px 0px 0px' },
    '& .MuiTab-fullWidth': {
      backgroundColor: `${getColor('background', 400)} !important`,
      justifyContent: 'initial',
      border: 'none',
      fontSize: 14,
      fontWeight: 500
    },
    '& .MuiTabs-indicator': { bottom: 0, top: 'unset' }
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    gap: '20px',
    width: '418px',
    height: '129px',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed #A0C8BA',
    borderRadius: '4px',
    backgroundColor: '#f9fffd',
    color: getColor('neutral', 900),
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  },
  uploadedFile: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 12px 0px',
    width: '418px',
    height: '40px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: '4px',
    border: '1px dashed #A0C8BA',
    backgroundColor: '#f9fffd'
  },
  rejectedFile: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px 0px',
    width: '418px',
    height: '40px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: '4px',
    border: '1px dashed #A0C8BA',
    backgroundColor: '#f9fffd'
  },
  uploadedFileTitle: {
    fontFamily: 'Work Sans',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '600',
    color: getColor('border', 300)
  },
  fileIcon: {
    marginLeft: '15px',
    height: '16px',
    width: '14px'
  },
  errorList: {
    paddingInlineStart: '15px',
    margin: '5px 0px 12px'
  },
  errorText: {
    color: getColor('error', 700),
    fontFamily: 'Work Sans',
    fontSize: '12px',
    fontWeight: '400'
  }
});

export const inputAdormentStyle = {
  width: '150px',
  height: '100%',
  marginLeft: '0px',
  justifyContent: 'flex-end'
};
export const inputAdormentStyleButtonOnly = {
  justifyContent: 'flex-start'
};

export const browseButtonStyle = {
  width: '103px',
  height: '30px',
  textTransform: 'none'
};

export const dialogBBoxStyle = (docVaultDisabled: any) => {
  const style = {
    '& .MuiDialog-paper': {
      minHeight: docVaultDisabled ? '350px' : '482px',
      maxHeight: '482px',
      width: '478px',
      borderRadius: '12px'
    }
  };
  return style;
};

export default useStyles;
