import React, { ReactNode, CSSProperties } from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';
import { Box, Typography, styled } from '@mui/material';
import { getColor } from '../../colors';
import { CheckCircle } from '@mui/icons-material';

interface ITimelineItem {
  label?: string;
  title?: string;
  subTitle?: string | ReactNode;
  render?: ReactNode;
  checked?: boolean;
  tooltip?: ReactNode;
}

export type TETimelineControlProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  /**
   * a classname for parent component.
   */
  className?: string;
  /**
   * a style object that directly targets mui classes.
   */
  style?: CSSProperties;
  /**
   * a style object for labels.
   */
  labelStyle?: CSSProperties;
  /**
   * a style object for titles.
   */
  titleStyle?: CSSProperties;
  /**
   * a style object for subtitles.
   */
  subTitleStyle?: CSSProperties;
  /**
   * a style object for timeline dots.
   */
  dotStyle?: CSSProperties;
  /**
   * an array of objects - actual data to be rendered.
   */
  items?: ITimelineItem[];
};

export function TimelineControl({
  items,
  labelStyle,
  titleStyle,
  subTitleStyle,
  dotStyle,
  className,
  style
}: TETimelineControlProps) {
  const StyledTimeline = styled(Timeline)(() => ({
    margin: 0,
    width: '100%'
  }));

  const StyledTimelineDot = styled(TimelineDot)(() => ({
    border: `1px solid ${getColor('border', 100)}`,
    margin: 0,
    padding: 5
  }));

  const StyledTimelineConnector = styled(TimelineConnector)(() => ({
    width: '1px',
    backgroundColor: getColor('neutral', 50),
    minHeight: '90px'
  }));

  const StyledTypographyLabel = styled(Typography)(() => ({
    fontSize: '11px',
    color: getColor('primary', 500),
    letterSpacing: '1px',
    textTransform: 'uppercase',
    fontWeight: 600
  }));

  const StyledTypographyTitle = styled(Typography)(() => ({
    marginTop: '5px',
    fontSize: '14px',
    color: getColor('border', 200),
    letterSpacing: '1px',
    lineHeight: '21px',
    fontWeight: 500
  }));

  const StyledTypographySubTitle = styled(Typography)(() => ({
    marginTop: '2px',
    fontSize: '13px',
    color: getColor('extra', 600),
    fontWeight: 400
  }));

  const StyledTimelineContent = styled(TimelineContent)(() => ({
    paddingTop: 0,
    marginTop: '-2px'
  }));

  return (
    <StyledTimeline
      className={className}
      sx={style}
      // placeholder={undefined}
      // onPointerEnterCapture={undefined}
      // onPointerLeaveCapture={undefined}
    >
      {items?.map((item: ITimelineItem, index: number) => (
        <TimelineItem key={index}>
          {/* remove opposite content  */}
          <TimelineOppositeContent style={{ display: 'none' }} />
          <TimelineSeparator sx={{ marginLeft: item?.checked ? '-4px' : 'unset' }}>
            {item.checked ? (
              //   <StyledTimelineDot>
              <CheckCircle fontSize="medium" sx={{ color: getColor('background', 700) }} />
            ) : (
              //   </StyledTimelineDot>
              <StyledTimelineDot style={dotStyle} variant="outlined" />
            )}
            {index != items?.length - 1 ? <StyledTimelineConnector /> : <></>}
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledTypographyLabel style={labelStyle}>{item.label}</StyledTypographyLabel>
            <StyledTypographyTitle style={titleStyle}>{item.title}</StyledTypographyTitle>
            <StyledTypographySubTitle style={subTitleStyle}>
              {item.subTitle} {item?.tooltip && item?.tooltip}
            </StyledTypographySubTitle>

            {item?.render && (
              <Box my={1} mb={2}>
                {item?.render}
              </Box>
            )}
          </StyledTimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
}
