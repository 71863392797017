import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useContext, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Box, Grid, Stack, Typography } from '@mui/material';
import './leadinvestor.css';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../../contexts/authContext';
import DealsManager from '../../../manager/DealsManager';
import { IconConstant } from '../../../../common/constants/IconConstants';
import { OutlinedButton } from '../../../../../ui-library/components/buttons/outlined-button';
import { getColor } from '../../../../../ui-library/colors';
import { ActionButton } from '../../../../../ui-library/components/buttons/action-button';

export default function LeadInvestorList(props: any) {
  const {
    user,
    isMandatory,
    carryError,
    labelTitle,
    investorList,
    notes,
    innerText,
    saveLeadInvestor,
    removeInvestor,
    setFieldValue,
    removeIcon,
    errors,
    labelName,
    values,
    textinfo,
    validateField,
    isZeno = false
  } = props;

  const [leadInvestor, setLeadInvestor] = useState<any>(null);
  const [leadCarry, setLeadCarry] = useState(null);
  const [openForm, setOpenForm] = useState<any>(null);
  const [onChangeError, setOnChangeError] = useState(false);
  const totalCarry = investorList.reduce((sum: any, item: any) => sum + Number(item.lead_carry), 0.0);
  const leadCarryAllocationLeft = values.total_lead_carry ? values.total_lead_carry - totalCarry : 0;
  const authContext = useContext(AuthContext);
  const dealManager = new DealsManager(authContext);

  const getLookupOptions = async (search: any) => {
    try {
      const syndicateId: any = localStorage.getItem('syndicate_id');
      let result = await dealManager.getLookupData(search, 'all_angel_investors', '', syndicateId);
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id
      }));
      return result;
    } catch (e: any) {
      toast.error(e.message || 'Error fetching lookup options');
    }
  };

  const addLeadInvestor = () => {
    const leadInvestorObj = { label: leadInvestor.label, value: leadInvestor.value, lead_carry: leadCarry };
    values.selected_lead_investor = leadInvestorObj;
    setOpenForm(false);
    saveLeadInvestor(setFieldValue, values);
  };

  return (
    <div className="edit-form-row">
      {labelTitle && (
        <label className="edit__profile_label-title">
          {labelTitle} {isMandatory ? <span className="text-danger">*</span> : ''}
        </label>
      )}

      <Grid container spacing={2}>
        {investorList?.length
          ? investorList.map((item: any, key: any) => (
              <Grid item xs={4} key={key}>
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  border="1px solid #d1d1d1"
                  borderRadius="4px"
                  p="4px"
                >
                  <div className="p-1">
                    <img
                      src={item.profile_image || IconConstant.USER_PROFILE_ICON}
                      height="30px"
                      width="30px"
                      className="rounded-circle"
                      alt=""
                    />
                  </div>

                  <div>
                    {!!item.label && (
                      <Typography variant="paragraph_03_medium">{`${item.label.slice(0, 15)}...`}</Typography>
                    )}
                    {!!item.lead_carry && <Typography variant="paragraph_03_bold">{`${item.lead_carry}%`}</Typography>}
                  </div>
                  {!isZeno && !!removeIcon && (
                    <div
                      className="ms-auto"
                      style={{ cursor: 'pointer' }}
                      onClick={() => removeInvestor(item.value, setFieldValue, investorList)}
                    >
                      <DeleteOutlinedIcon />
                    </div>
                  )}
                </Stack>
              </Grid>
            ))
          : null}
      </Grid>
      <Grid container item xs={3} mt={1}>
        {!isZeno && (user === 'founder' ? investorList.length === 0 : true) && (
          <OutlinedButton onClick={() => setOpenForm(true)} customStyles={{ whiteSpace: 'nowrap' }}>
            <AddIcon style={{ marginRight: '10px' }} />
            {innerText}
          </OutlinedButton>
        )}
      </Grid>
      {!!textinfo && (
        <Typography variant="caption_regular" my="10px" textAlign="left">
          <span style={{ color: getColor('neutral', 600) }}>{textinfo}</span>
        </Typography>
      )}

      {!!openForm && (
        <Stack direction="row" gap={2}>
          <Box>
            <AsyncSelect
              styles={{
                control: (base) => ({
                  ...base,
                  height: '40px',
                  minHeight: '40px',
                  width: '330px',
                  fontFamily: 'Work Sans',
                  color: '#040607',
                  fontSize: '14px',
                  borderRadius: '4px',
                  border: '1px solid #d1d1d1',
                  ':hover': {
                    borderColor: getColor('primary', 700)
                  },
                  ':focus': {
                    borderColor: getColor('primary', 700)
                  },
                  ':active': {
                    borderColor: getColor('primary', 700)
                  },
                  boxShadow: 'none'
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                  return {
                    ...styles,
                    fontFamily: 'Work Sans',
                    zIndex: 9999
                  };
                },
                menu: (styles) => {
                  return {
                    ...styles,
                    zIndex: 9999,
                    fontFamily: 'Work Sans'
                  };
                }
              }}
              loadOptions={getLookupOptions}
              placeholder="Enter the name of your investor"
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? 'Please Enter 1 or more character' : 'No results found'
              }
              onChange={(investor) => {
                setLeadInvestor(investor);
              }}
            />
          </Box>

          <Box flex={1}>
            <input
              type="number"
              min="0"
              max={leadCarryAllocationLeft}
              name="carry"
              style={{
                width: '100%',
                padding: '8px 14px',
                fontFamily: 'Work Sans',
                color: '#040607',
                fontSize: '14px',
                borderRadius: '4px',
                boxShadow: 'none'
              }}
              className="lead_carry_input"
              onChange={(carry: any) => {
                let carryValue = carry.target.value;
                if (carry.target.value < 0) carryValue = 0;
                if (carry.target.value > leadCarryAllocationLeft) {
                  carryValue = leadCarryAllocationLeft;
                }
                if (carry.target.value > leadCarryAllocationLeft) {
                  carryValue = leadCarryAllocationLeft;
                  setOnChangeError(true);
                } else {
                  setOnChangeError(false);
                }
                carry.target.value = carryValue;
                setLeadCarry(carryValue);
              }}
              placeholder="Enter lead carry"
            />

            {onChangeError ? (
              <Typography variant="subtitle2" color={getColor('error', 800)}>
                Lead carry allocation should be less than or equal to {leadCarryAllocationLeft}%
              </Typography>
            ) : values.total_lead_carry > 0.5 ? (
              <Typography variant="subtitle2">
                {`Lead carry allocation left is ${leadCarryAllocationLeft}%`}{' '}
              </Typography>
            ) : (
              ''
            )}
          </Box>

          <ActionButton
            onClick={() => {
              addLeadInvestor();
              setTimeout(() => {
                validateField('lead_investor_list');
              }, 1000);
            }}
          >
            Save
          </ActionButton>
        </Stack>
      )}

      {(isMandatory || carryError) && errors && errors[labelName] && (
        <Typography variant="subtitle1" color={getColor('error', 800)}>
          {errors[labelName]}
        </Typography>
      )}
      {notes && <div className="edit_profile_notes"> {notes} </div>}
    </div>
  );
}
