import requestManager from '../../api/requestManager';

const impersonateUserLogin = (impersonatedAccessToken: string) =>
  requestManager.apiPost(
    `/api/v1/user_components/me/glasdoor_login`,
    { access_token: impersonatedAccessToken },
    {},
    true
  );

export default {
  impersonateUserLogin
};
