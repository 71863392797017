import { Box, Tooltip, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';

const SettingCard: FC<any> = ({ syndicateDetail = {}, primaryAdmin = {} }) => {
  const { syndicate_name = null, formated_joined_lv_date = null, cc_email = null, access = false } = syndicateDetail;
  const { name = null } = primaryAdmin;
  const cardOptions = [
    {
      label: 'Syndicate Name',
      spacing: 3,
      value: syndicate_name ?? '-'
    },
    {
      label: 'Joined on LetsVenture',
      spacing: 3,
      value: formated_joined_lv_date ?? '-'
    },
    {
      label: 'Primary Admin',
      spacing: 3,
      value: name ?? '-'
    },

    {
      label: 'Allow backers to access public Deals',
      spacing: 3,
      value: access ? 'Yes' : 'No',
      helpText: 'If yes, all new users will get access to public deals on the platform by default'
    },
    {
      label: 'cc emails',
      spacing: 8,
      renderer: cc_email?.length ? (
        cc_email?.length > 1 ? (
          <>
            <Typography align="left" variant="heading_04_medium" fontWeight={600}>
              {cc_email[0]}{' '}
              <Tooltip
                title={cc_email.slice(1).join(', ')}
                placement="top-start"
                arrow
                componentsProps={{ tooltip: { sx: { background: '#f2f2f2', color: '#000' } } }}
              >
                <Typography display="inline" variant="paragraph_03_bold">
                  <LinkButton title={`+ ${cc_email?.length - 1} more`} />{' '}
                </Typography>
              </Tooltip>
            </Typography>
          </>
        ) : (
          <>{cc_email} </>
        )
      ) : (
        '-'
      )
    }
  ];

  return (
    <Box className="card_border text-left" mb={3}>
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default SettingCard;
