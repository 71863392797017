import React, { ReactNode, CSSProperties } from 'react';
import './section-header-style.css';
import Typography from '@mui/material/Typography';
import { LVThemeProvider } from '../theme/lvtheme';
import { getColor } from '../../colors';

export type SectionHeaderProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;

  customStyles?: CSSProperties;
};

export function SectionHeader({ children, customStyles = {} }: SectionHeaderProps) {
  return (
    <LVThemeProvider>
      <Typography
        variant="heading_07_bold"
        sx={{
          color: getColor('neutral', 900),
          fontFamily: 'Work Sans',
          lineHeight: '30px',
          fontSize: '18px',
          fontWeight: 600,
          fontStyle: 'normal',
          ...customStyles
        }}
      >
        {children}
      </Typography>
    </LVThemeProvider>
  );
}
