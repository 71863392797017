import React, { CSSProperties, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import { getColor } from '../../colors';

export type HorizontalTabsProps = {
  /**
   * An array of objects with name, value and an optional property disabled.
   * e.g. [{name: 'Tab One', value: 'tab_one', content: <p>Tab A content</p> }]
   */
  tabs: {
    name: string;
    value: string;
    content: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
    iconPosition?: string;
  }[];
  /**
   * A string that should match with the value property of one of the objects in tabs prop
   */
  selectedTab: string;
  /**
   * A callback function that gets triggered whenever tab selection changes
   * It receives the value property of the selected tab as an argument
   */
  onTabChange?: Function;
  /**
   * Disable ripple animation of tabs
   */
  disableRipple?: boolean;
  /**
   * A boolean that indicates if the tabs should be scrollable. When false will show full width
   */
  scrollable?: boolean;
  /**
   * A boolean that indicates if you want padding on left or right side of the content.
   */
  xAxisPadding?: boolean;

  customTabsStyles?: CSSProperties;

  customTabStyles?: CSSProperties;

  /**
   * A boolean that indicates if the selected tab indicator should be in the bottom
   */
  bottomIndicator?: boolean;
  /**
   *prop to give minimum width for each tab .it accepts number .For example : if you give 250 , then it will set the tab minimum width 250px.
   */
  tabMinWidth?: number;
  /**
   *custom styles for tab content container
   */
  customTabContainerStyles?: any;
};

const AntTabs = withStyles({
  root: {
    borderBottom: `1px solid ${getColor('border', 0)}`,
    minHeight: 'auto !important'
  },
  selected: {
    backgroundColor: `${getColor('shades', 0)}!important`,
    border: 'none'
  },
  indicator: {
    top: (props: any) => (props.bottomIndicator ? 'auto' : '0px'),
    backgroundColor: `${getColor('secondary', 900)}!important`,
    color: `${getColor('secondary', 900)}!important`
  }
})(Tabs);

const AntTab = styled((props: React.ComponentProps<any>) => <Tab disableRipple={props.disableRipple} {...props} />)(
  (props: React.ComponentProps<any>) => ({
    fontWeight: 500,
    letterSpacing: '0.5px',
    color: getColor('neutral', 900),
    minHeight: '40px',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '10px 16px',
    minWidth: props.tabMinWidth ? `${props.tabMinWidth}px !important` : 'auto',
    '&:hover': {
      color: `${getColor('secondary', 900)}!important`
    },
    '&.Mui-selected': {
      color: getColor('secondary', 900),
      border: 'none'
    }
  })
);

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};
export function HorizontalTabs({
  tabs,
  selectedTab,
  onTabChange,
  disableRipple = true,
  scrollable = false,
  xAxisPadding = true,
  customTabsStyles = {},
  customTabStyles = {},
  bottomIndicator = false,
  tabMinWidth,
  customTabContainerStyles = {}
}: HorizontalTabsProps) {
  const filteredTabs = tabs.filter((value) => Object.keys(value).length !== 0);
  const selectedTabIndex = tabs
    .filter((value) => Object.keys(value).length !== 0)
    .findIndex((tab) => tab.value === selectedTab);
  const selectedTabContent = tabs
    .filter((value) => Object.keys(value).length !== 0)
    .filter((tab) => tab.value === selectedTab)[0]?.content;

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newTab: number) => {
    onTabChange && onTabChange(filteredTabs[newTab].value);
  };

  return (
    <>
      <AntTabs
        value={selectedTabIndex}
        onChange={handleChange}
        variant={scrollable ? `scrollable` : 'fullWidth'}
        aria-label="trica-horizontal-tab"
        sx={{ ...customTabsStyles }}
        bottomIndicator={bottomIndicator}
        id="horizontalTabsHeader"
      >
        {filteredTabs &&
          filteredTabs.map((tab, index) => (
            <AntTab
              key={`trica-horizontal-tab-${index}`}
              label={tab?.name}
              {...a11yProps(index)}
              disabled={tab.disabled}
              disableRipple={disableRipple}
              wrapped
              sx={{ ...customTabStyles }}
              tabMinWidth={tabMinWidth}
              icon={tab.icon}
              iconPosition={tab?.iconPosition}
            />
          ))}
      </AntTabs>
      <Box sx={{ px: xAxisPadding ? 2 : 0, py: 1.5, ...customTabContainerStyles }}>{selectedTabContent}</Box>
    </>
  );
}
