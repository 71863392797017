import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Field } from 'formik';
import { getColor } from '../../colors';
import { InputField } from '../form-elements/CustomTextField';
import { Dropdown } from './core-dropdown';

export type TimePeriodDropdownProps = {
  /**props for the customTextField,(for formik purposes) we can use this*/
  customTextFieldProps?: any;
  /**props for the dropdown,(for formik purposes) we can use this*/
  coreDropdownProps?: any;
  /**set the width of the dropdown , which is at the right corner*/
  dropdownWidth?: string | number;
  /*boolean to show error message for textfield*/
  error?: boolean;

  /*show dropdown before the textfield*/
  showDropdownFirst?: boolean;

  id: any;

  form?: any;
  /*pass below props to show error for fieldArray along with error true */
  fieldArray?: boolean;
  formName?: any;
  index?: any;
  fieldName?: any;
  isObject?: string;
  helperText?: string;
};

const useStyles = makeStyles({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none !important'
    },
    '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
      color: `${getColor('primary', 700)} !important`
    },
    border: `1px solid ${getColor('extra', 600)}`,
    '&:hover': {
      border: `1px solid ${getColor('primary', 700)}`
    },
    '&:focus': {
      border: `1px solid ${getColor('primary', 700)}`
    },
    borderRadius: '4px'
  },
  divider: {
    height: '30px',
    borderLeft: `1px solid ${getColor('extra', 600)}`,
    alignSelf: 'center'
  },
  errorMsg: {
    color: `${getColor('error', 1000)} !important`,
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: 0,
    marginLeft: '14px'
  }
});

export function DropdownTextField({
  customTextFieldProps,
  coreDropdownProps,
  dropdownWidth,
  error,
  showDropdownFirst = false,
  form,
  fieldArray,
  formName,
  index,
  fieldName,
  isObject,
  id,
  helperText
}: TimePeriodDropdownProps) {
  const classes = useStyles();
  const InputFieldProps = {
    ...customTextFieldProps,
    InputLabelProps: {
      style: {
        background: `${getColor('shades', 0)}`,
        padding: '0px 2px'
      }
    }
  };
  const dropdownProps = {
    ...coreDropdownProps,
    InputLabelProps: {
      style: {
        background: `${getColor('shades', 0)}`,
        padding: '0px 2px'
      }
    }
  };
  const getErrorMessage = () => {
    let errorMessage = '';
    if (form) {
      if (
        fieldArray &&
        error &&
        (form?.touched?.[formName]?.[index]?.[fieldName] || form?.touched?.[`${formName}.${index}.${fieldName}`])
      ) {
        errorMessage = form?.errors?.[formName]?.[index]?.[fieldName];
      } else if (error && form?.touched[id]) {
        errorMessage = form?.errors[id];
      } else if (isObject) {
        errorMessage = form?.errors?.[isObject]?.[fieldName];
      }
    }
    return errorMessage;
  };
  return (
    <div>
      <div
        className={classes.parent}
        style={{
          flexDirection: showDropdownFirst ? 'row-reverse' : 'row'
        }}
        id="test"
      >
        <div style={{ flexGrow: 1 }}>
          <InputField {...InputFieldProps} />
        </div>
        <div className={classes.divider}></div>
        <div style={{ minWidth: dropdownWidth ? dropdownWidth : '100px' }}>
          <Dropdown {...dropdownProps} />
        </div>
      </div>
      {getErrorMessage() && (
        <Typography variant="caption_regular" color={getColor('error', 1000)} mt={'3px'} marginLeft="14px">
          {getErrorMessage()}
        </Typography>
      )}
      {helperText && (
        <Typography variant="caption_regular" marginTop="5px" textAlign="left">
          <span style={{ color: getColor('neutral', 600) }}>{helperText}</span>
        </Typography>
      )}
    </div>
  );
}

export function TimePeriodDropdown(props: any) {
  return (
    <>
      <Field component={DropdownTextField} {...props} />
    </>
  );
}
