import { toast } from 'react-toastify';
import ImpersonateLogin from '../service/impersonateLogin';

export default class ImpersonateManager {
  constructor() {}

  async impersonateUserLogin(impersonatedAccessToken: string) {
    try {
      const response: any = await ImpersonateLogin.impersonateUserLogin(impersonatedAccessToken);
      return response?.data;
    } catch (error: any) {
      toast.error(error.message);
    }
  }
}
