import { isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

export const validateArray = (arr: any) => {
  if (Array.isArray(arr)) {
    if (arr?.length) return true;
  }
  return false;
};

export const getCurrencySymbol = (name: string) => (name === 'USD' ? '\u0024' : '\u20B9');

export const rupeeFormatter = new Intl.NumberFormat('en-IN', {
  currency: 'INR'
});

export const isValidMobileNumber: any = (number: any, yup = false) => {
  if (yup) {
    return Yup.string()
      .trim()
      .test('test-is-valid-mobile', 'enter a valid mobile number with the country code (eg. +919800980098)', (value) =>
        isValidMobileNumber(value, false)
      )
      .required('mobile number is required');
  }
  if (number) return isValidPhoneNumber(number);
  return false;
};

export const isEmpty = (value: any, falseStrings = ['']) =>
  value instanceof File
    ? false
    : typeof value === 'undefined' ||
      value === null ||
      Number.isNaN(value) ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0) ||
      (typeof value === 'string' && falseStrings.includes(value));
