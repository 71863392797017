import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PostHogManager from './analytics/postHogManager';
import * as Sentry from '@sentry/browser';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AuthContextProvider } from './contexts/authContext';
import { PermissionContextProvider } from './contexts/permissionContext';
import { BrowserRouter } from 'react-router-dom';
import { LVThemeProvider } from './ui-library/components/theme/lvtheme';

Sentry.init({
  dsn: 'https://2177427d85934a3b854f648d7673e18c@o1396885.ingest.sentry.io/6721630',
  // Performance monitoring options
  tracesSampleRate: 1.0 // Adjust the sample rate as needed
});

PostHogManager.initiatePostHog();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <ToastContainer />
    <LVThemeProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <PermissionContextProvider>
            <App />
          </PermissionContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </LVThemeProvider>
  </>
);

reportWebVitals();
