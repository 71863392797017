const FILE_NAME_REGEX = /^[A-Za-z][ A-Za-z0-9_-]*[.][A-Za-z0-9]+$/;
const fileNameValidator = (file: any) => {
  if (!FILE_NAME_REGEX.test(file.name)) {
    return {
      code: 'file-invalid-type',
      message:
        'Unsupported file name. Please use alphabets, numbers, hyphen, underscore, space only. First character should be an alphabet'
    };
  }
  return null;
};

export const fileValidator = (file: any, disableDefaultValidation: boolean, customFileValidator: any) => {
  let fileNameValidation = !disableDefaultValidation ? fileNameValidator(file) : null;
  if (fileNameValidation) return fileNameValidation;

  /**
   * customFileValidator function should return an object like
   *     { code: '', message: '' }
   */
  let customValidation = customFileValidator ? customFileValidator(file) : null;
  return customValidation;
};
