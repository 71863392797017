import { Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Topbar } from '../../../ui-library/components/navs/topbar';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { PAGE_NOT_FOUND, SYNICATE_LOGO_COLOUR } from '../constants/CommonConstants';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Topbar position="relative">
        <img src={SYNICATE_LOGO_COLOUR} alt="trica" style={{ width: '200px', height: 'auto' }} />
      </Topbar>

      <Grid container textAlign="center">
        <Grid
          xs={12}
          ml="-3%"
          sx={{ width: { xs: '200px', lg: 'auto' }, '.pgn': { width: { xs: '100%', lg: 'auto' } } }}
        >
          <img src={PAGE_NOT_FOUND} alt="page not found" className="pgn" />
        </Grid>
        <Grid xs={12} alignItems="center" mt={3} gap={{ xs: 0, lg: 2 }}>
          <Typography variant="heading_04_medium" textTransform="uppercase">
            Page not found
          </Typography>
          <Typography variant="subtitle2" mb={1}>
            Sorry, the page you are looking for doesn&apos;t exist
          </Typography>
          <ActionButton
            onClick={() => {
              navigate('/');
            }}
          >
            BACK TO DASHBOARD
          </ActionButton>
        </Grid>
      </Grid>
    </>
  );
};
export default PageNotFound;
