import React from 'react';
import { Button, Divider, MenuItem, styled, Typography } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getColor } from '../../colors';

export type DropdownButtonProps = {
  /**
   * a text to be rendered in the dropdown button.
   */
  buttonText?: string;

  /**
   * An array of options to be rendered in dropdown menu
   */
  options?: { label: string; subText?: string; subTextStyle?: any; disabled?: boolean; labelStyle?: any }[];

  /**
   * A callback function which receives the value of selected option
   */
  onOptionClick?: Function;
  /**
   * boolean to render button with filled background
   */
  fill?: boolean;
  /**
   * width to be applied on  button
   */
  width?: number | string;
  /**
   * prop to hide the button
   */
  hidden?: boolean;
  /**Custom styles to button */
  customStyles?: object;

  menuItemStyle?: object;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    width: 207,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px'
  }
}));

const StyledButton = styled(Button)(() => ({
  height: '36px',

  boxShadow: 'none',
  border: `1px solid ${getColor('primary', 700)} `,

  fontSize: '14px',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: getColor('shades', 0),
    boxShadow: 'none'
  }
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  height: '52px'
}));

const StyledDivider = styled(Divider)(() => ({
  margin: '0 !important'
}));

const StyledEmptyMenuItem = styled(MenuItem)(() => ({
  height: '60px',
  justifyContent: 'center'
}));

export function DropdownButton({
  buttonText,
  onOptionClick,
  options,
  fill,
  width,
  customStyles = {},
  menuItemStyle = {},
  hidden = false
}: DropdownButtonProps) {
  const [anchorElm, setAnchorElm] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElm);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElm(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElm(null);
  };

  const handleMenuClick = (value: string) => {
    handleClose();
    onOptionClick && onOptionClick(value);
  };

  if (hidden) {
    return <div />;
  }

  return (
    <div>
      <StyledButton
        variant="contained"
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        style={{
          color: fill ? 'white' : getColor('primary', 700),
          backgroundColor: fill ? getColor('primary', 700) : 'white',
          width: width ? width : 'auto',
          ...customStyles
        }}
      >
        {buttonText ? buttonText : 'DropDown'}
      </StyledButton>
      <StyledMenu sx={{ ...menuItemStyle }} anchorEl={anchorElm} open={open} onClose={handleClose}>
        {options ? (
          options.map(({ label, subText, subTextStyle = {}, disabled, labelStyle = {} }, index) => (
            <div key={`dropdown-button-${index}`}>
              <StyledMenuItem disabled={disabled} onClick={() => handleMenuClick(label)}>
                <div>
                  <Typography sx={{ ...labelStyle }}>{label}</Typography>
                  {subText && <Typography sx={{ ...subTextStyle }}>{subText}</Typography>}
                </div>
              </StyledMenuItem>
              {index !== options.length - 1 && <StyledDivider />}
            </div>
          ))
        ) : (
          <StyledEmptyMenuItem disabled>No Option Available</StyledEmptyMenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
