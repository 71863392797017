import { Typography } from '@mui/material';
import React from 'react';

const DealTypeTooltip = () => {
  return (
    <>
      <Typography variant="paragraph_03_bold">Deal Status can be:</Typography>
      <Typography variant="caption_regular">
        1:{' '}
        <Typography variant="caption_uppercase" display="inline">
          Live{' '}
        </Typography>
        (open for commit by investors)
      </Typography>
      <Typography variant="caption_regular">
        2:{' '}
        <Typography variant="caption_uppercase" display="inline">
          Draft{' '}
        </Typography>
        (Deal is yet to finalise to get live)
      </Typography>
      <Typography variant="caption_regular">
        3:{' '}
        <Typography variant="caption_uppercase" display="inline">
          Funded{' '}
        </Typography>
        (it will start appearing in investor's portfolio)
      </Typography>
      <Typography variant="caption_regular">
        4:{' '}
        <Typography variant="caption_uppercase" display="inline">
          Closed{' '}
        </Typography>
        (not visible to investors)
      </Typography>
      <Typography variant="caption_regular">
        5:{' '}
        <Typography variant="caption_uppercase" display="inline">
          Cancelled{' '}
        </Typography>
        (Deal is cancelled for changes which are not appropriate)
      </Typography>
    </>
  );
};

export default DealTypeTooltip;
