import React, { ReactNode, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Collapse, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getColor } from '../../colors';
import { LVThemeProvider } from '../theme/lvtheme';

const useStyles = makeStyles({
  accordionBox: {
    flexDirection: 'column',
    borderRadius: '4px'
  },
  accordionBoxWithShadow: {
    flexDirection: 'column',
    borderRadius: '4px',
    boxShadow: '4px 0px 30px rgba(115, 118, 122, 0.16)'
  },
  accordionDetail: {
    padding: '20px'
  },
  accordionDetailInsideCardContainer: {
    padding: '20px 0px'
  },
  pointerIcon: {
    cursor: 'pointer'
  },
  headerOnclose: {
    borderRadius: '4px',
    flexDirection: 'row',
    borderColor: getColor('primary', 50),
    backgroundColor: getColor('background', 400),
    borderStyle: 'solid',
    borderWidth: '1px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '12px'
  },
  accordionActions: {
    display: 'flex',
    gap: '12px'
  },
  rotateIconDown: {
    transition: 'transform 1s',
    transform: 'rotate(90deg)',
    width: '20px'
  },
  rotateIconUp: {
    transition: 'transform 1s',
    transform: 'rotate(0deg)',
    width: '20px'
  },
  headerOnOpen: {
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: getColor('primary', 50),
    borderColor: getColor('primary', 50),
    borderStyle: 'solid',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    borderWidth: '1px',
    padding: '12px'
  },
  circleIcon: {
    marginLeft: '8px',
    marginTop: '2px',
    fontSize: '20px'
  }
});

export type ExpansionPanelProps = {
  /**
   * title of the expansion panel
   */
  title: string | ReactNode;
  /**
   * add edit icon and call this funtion when use click on that
   */
  defaultExpanded: boolean;
  /**
   * add edit icon and call this funtion when use click on that
   */
  onEditClick?: Function;
  /**
   * expand panel when clicked on edit button if not expanded
   */
  expandOnEditClick?: Boolean;
  /**
   * add delete icon and call this funtion when use click on that
   */
  onDeleteClick?: Function;
  /**
   * Expansion Panel content
   */
  children?: ReactNode;
  /** custom function to handle toogle of panel for controlled component*/
  handleToggle?: Function;
  /** custom state to handle open and close of panel for controlled component*/
  open?: boolean;

  /**
   * Custom accordion box style after expand
   */
  expandedAccordionBoxStyle?: object;
  /**
   * Custom accordion box style before expand
   */
  accordionBoxStyle?: object;
  /**
   * Custom accordion header style after expand
   */
  expandedHeaderStyle?: object;
  /**
   * Custom accordion header style before expand
   */
  headerStyle?: object;

  /**Check icon */
  checkIcon?: boolean;
  /** boolean value to show success check icon */
  successCheckIcon?: boolean;
  /** boolean value to show success check icon */
  isInsideCardContainer?: boolean;
  /** enable the toogled state of panel on header click */
  enableExpansionOnHeaderClick?: boolean;
};

export function ExpansionPanel(props: ExpansionPanelProps) {
  const {
    expandOnEditClick,
    defaultExpanded,
    title,
    children,
    onDeleteClick: handleDeleteClick,
    onEditClick: handleEditClick,
    handleToggle,
    open,
    expandedAccordionBoxStyle,
    accordionBoxStyle,
    expandedHeaderStyle,
    headerStyle,
    checkIcon = false,
    successCheckIcon = false,
    isInsideCardContainer = false,
    enableExpansionOnHeaderClick = false
  } = props;
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(open ? open : defaultExpanded);

  const toggleOpen = () => setIsExpanded(!isExpanded);
  const handleClick = () => {
    if (handleToggle) {
      handleToggle();
    } else toggleOpen();
  };

  useEffect(() => {
    setIsExpanded(open ? open : defaultExpanded);
  }, [defaultExpanded, open]);

  return (
    <LVThemeProvider>
      <div
        className={isExpanded && !isInsideCardContainer ? classes.accordionBoxWithShadow : classes.accordionBox}
        style={isExpanded ? expandedAccordionBoxStyle : accordionBoxStyle}
      >
        <div
          className={isExpanded ? classes.headerOnOpen : classes.headerOnclose}
          style={isExpanded ? expandedHeaderStyle : headerStyle}
          onClick={(event) => {
            if (enableExpansionOnHeaderClick) {
              handleClick();
            }
          }}
        >
          <div>{typeof title == 'string' ? <Typography variant="heading_05_medium">{title}</Typography> : title}</div>
          <div className={classes.accordionActions}>
            {handleEditClick && (
              <img
                src="https://dsjvxb1plg419.cloudfront.net/v2.0/EditBlue.svg"
                className={classes.pointerIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  if (expandOnEditClick && !isExpanded) {
                    setIsExpanded(true);
                  }
                  handleEditClick();
                }}
              />
            )}
            {handleDeleteClick && (
              <img
                src="https://dsjvxb1plg419.cloudfront.net/v2.0/DeleteRed.svg"
                className={classes.pointerIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleDeleteClick();
                }}
              />
            )}
            {checkIcon && (
              <CheckCircleIcon
                className={classes.circleIcon}
                style={{ color: successCheckIcon ? getColor('success', 500) : getColor('extra', 1500) }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            )}

            <img
              className={`${classes.pointerIcon} ${isExpanded ? classes.rotateIconDown : classes.rotateIconUp}`}
              src="https://dsjvxb1plg419.cloudfront.net/v2.0/AccordionOpen.svg"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleClick();
              }}
            />
          </div>
        </div>

        <Collapse in={isExpanded}>
          <div
            className={!isInsideCardContainer ? classes.accordionDetail : classes.accordionDetailInsideCardContainer}
          >
            {children}
          </div>
        </Collapse>
      </div>
    </LVThemeProvider>
  );
}

ExpansionPanel.defaultProps = {
  defaultExpanded: false,
  expandOnEditClick: false,
  onEditClick: undefined,
  onDeleteClick: undefined,
  enableExpansionOnHeaderClick: false
};
