export const CommunicationLogConstant: any = {
  not_committed: 'amount not committed',
  not_transfered: 'amount not transferred',
  no_ca_sign: 'no CA sign',
  deal_invite: 'deal invite',
  syndicate_invite: 'syndicate invite',
  not_viewed: 'deal not viewed',
  no_profile: 'profile not completed',
  launch_deal: 'launch deal'
};
