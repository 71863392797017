import { Box, LinearProgress, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../../ui-library/components/navs/breadcrumb';
import { HorizontalTabs } from '../../../../ui-library/components/tabs/horizontal-tabs';
import { DEAL_FORM_TAB_OPTIONS, DealTabType } from '../../constants/IndividualDealConstant';
import StartupInfoForm from './StartupInfoForm';
import SchemeInfoForm from './SchemeInfoForm';
import { API_STATUS } from '../../../common/constants/CommonConstants';

const DealForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { viewMode } = location?.state ?? {};

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: id ? (viewMode ? 'View Deal' : 'Edit Deal') : 'Launch New Deal',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: id ? (viewMode ? 'View Deal' : 'Edit Deal') : 'Launch New Deal',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const [selectedTab, setSelectedTab] = useState(DealTabType.STARTUP_INFO);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isDraftMode, setIsDraftMode] = useState(true);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      {apiStatus === API_STATUS.LOADING && (
        <LinearProgress sx={{ position: 'fixed', top: 0, right: 0, width: '100%' }} />
      )}
      <Breadcrumb
        steps={breadcrumbData.steps}
        breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
        pageHeader={breadcrumbData.pageHeader}
        stepKey={breadcrumbData.stepKey}
      />
      <Box width="40%">
        <HorizontalTabs
          tabs={DEAL_FORM_TAB_OPTIONS}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => {
            if (id && !isDraftMode) setSelectedTab(tab);
          }}
        />
      </Box>

      {selectedTab === DealTabType.STARTUP_INFO && (
        <StartupInfoForm
          apiStatus={apiStatus}
          setApiStatus={setApiStatus}
          setSelectedTab={setSelectedTab}
          viewMode={viewMode}
          setIsDraftMode={setIsDraftMode}
        />
      )}
      {selectedTab === DealTabType.SCHEME_INFO && (
        <SchemeInfoForm apiStatus={apiStatus} setApiStatus={setApiStatus} viewMode={viewMode} />
      )}
    </Box>
  );
};

export default DealForm;
