import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';
import { CardContainer } from '../../../ui-library/components/card/card-container';

const LiveDealSkeleton = () => {
  const cardOptions = [
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    }
  ];

  const cardOptions2 = [
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    },
    {
      label: <Skeleton variant="text" width={100} height={14} />,
      spacing: 3,
      renderer: <Skeleton variant="text" width="60%" height={23} />
    }
  ];
  return (
    <>
      <Box className="card_border text-left" mb={3}>
        <Box gap={2} display="flex" alignItems="center" padding="1.875rem" pb={0}>
          <Skeleton variant="rectangular" height={25} width={25} />
          <Skeleton variant="text" width={150} />
        </Box>
        <CardContainer options={cardOptions} />
        <Box px={'1.875rem'} mb={'1rem'} sx={{ mt: 1 }}>
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" height={40} />
          <Stack direction="row" justifyContent="space-between">
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={200} />
          </Stack>
        </Box>
        <CardContainer options={cardOptions2} />
      </Box>
    </>
  );
};

export default LiveDealSkeleton;
