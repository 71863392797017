import React, { FC, useContext, useEffect, useState } from 'react';
import { TimelineControl } from '../../../ui-library/components/timeline/TimelineControl';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';

import { DocumentListCard } from '../../../ui-library/components/card/document-list-card';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { OutlinedButton } from '../../../ui-library/components/buttons/outlined-button';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DealsManager from '../manager/DealsManager';
import { AuthContext } from '../../../contexts/authContext';
import { Link } from 'react-router-dom';
import TableLogo from '../../common/components/TableLogo';
import { HelpOutlineOutlined } from '@mui/icons-material';
import RejectReasonTooltip from './RejectReasonTooltip';

const DraftDeal: FC<any> = ({ schemeData, startupData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const dealManager = new DealsManager(authContext);

  const [status, setStatus] = useState<any>(null);

  const getDealStatus = () => {
    try {
      const result = dealManager.getDealStatus(startupData);
      setStatus(result);
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getDealStatus();
  }, []);

  const handleEdit = () => navigate(`/deals/edit/${startupData?.id}`);
  const handlePublish = () =>
    navigate(`/deals/${startupData?.id}/publish-deal`, {
      state: { permalink: startupData?.permalink, startupName: startupData?.name }
    });
  const handleDownload = (url: string) => {
    try {
      window.open(url, '_blank', 'noreferrer');
    } catch (e: any) {
      toast.error('Error in fetching document');
    }
  };

  const downloadSchemeDoc = () => handleDownload(startupData?.scheme_data?.scheme_doc);
  const downloadLeadSchemeDoc = () => handleDownload(startupData?.scheme_data?.lead_scheme_doc);

  const hasSubmitted = [0, 1].includes(startupData?.scheme_data?.fund_raising_status);

  return (
    <Box
      className="card_border"
      sx={{
        padding: '1.875rem',
        mb: 2
      }}
    >
      <Box gap={2} display="flex" alignItems="center" mb={2}>
        <TableLogo url={startupData?.logo} />

        {!id ? (
          <Link to={`/deals/${startupData?.id}`} style={{ textDecoration: 'none' }}>
            <SectionHeader>{startupData?.name}</SectionHeader>
          </Link>
        ) : (
          <SectionHeader>{startupData?.name}</SectionHeader>
        )}
      </Box>
      <Box pl={3}>
        <TimelineControl
          subTitleStyle={{}}
          items={[
            {
              label: 'Step 1: Edit Deal Info and submit to LetsVenture team for review.',
              title: status?.step1Text ?? '',

              checked: true,
              render: (
                <OutlinedButton
                  disabled={
                    (hasSubmitted ||
                      (startupData?.scheme_data?.is_scheme_verified == true && startupData?.scheme_data?.scheme_doc)) &&
                    startupData?.is_rejected === false
                  }
                  onClick={handleEdit}
                >
                  Edit Deal / Submit to LV for Review
                </OutlinedButton>
              )
            },
            {
              label: 'Step 2: Approval from LetsVenture team',
              title: status?.step2Text ?? '',
              subTitle:
                startupData?.reject_reason && !status?.awaitingApproval ? (
                  <Typography display="inline">
                    <Typography display="inline" fontWeight={500}>
                      Reason:{' '}
                    </Typography>
                    {startupData?.reject_reason}{' '}
                  </Typography>
                ) : (
                  ''
                ),
              tooltip: startupData?.reject_reason && !status?.awaitingApproval && (
                <Tooltip
                  componentsProps={{ tooltip: { sx: { background: '#f2f2f2', color: '#000' } } }}
                  title={<RejectReasonTooltip />}
                  placement="top-start"
                  arrow
                  sx={{ position: 'relative' }}
                >
                  <HelpOutlineOutlined fontSize="small" sx={{ color: 'text.primary' }} />
                </Tooltip>
              ),
              checked: startupData?.is_approved,
              render:
                startupData?.scheme_data?.isSchemeVerified &&
                (startupData?.scheme_data?.scheme_doc || startupData?.scheme_data?.lead_scheme_doc) ? (
                  <Stack direction="row" gap={2}>
                    {startupData?.scheme_data?.scheme_doc ? (
                      <DocumentListCard handleDownload={downloadSchemeDoc} documentName="Scheme Doc" width="20%" />
                    ) : null}
                    {startupData?.scheme_data?.lead_scheme_doc ? (
                      <DocumentListCard
                        handleDownload={downloadLeadSchemeDoc}
                        documentName="Lead Scheme Doc"
                        width="24%"
                      />
                    ) : null}
                  </Stack>
                ) : null
            },
            {
              label: 'Step 3: Publish the Deal',
              render: (
                <ActionButton disabled={!startupData?.is_approved} onClick={handlePublish}>
                  Publish Deal
                </ActionButton>
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};

export default DraftDeal;
