import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import DashboardService from '../service/DashboardService';

export default class DashboardManager {
  private userId: string;

  constructor(authContext: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
  }

  async getDashboardDetail() {
    try {
      let response: any = await DashboardService.getDashboardDetail(this.userId);
      response = response?.data;
      let ongoing_startups = response?.ongoing_startups ?? [];
      ongoing_startups = ongoing_startups?.sort(
        (a: any, b: any) => new Date(b.launched_date).getTime() - new Date(a.launched_date).getTime()
      );
      return { ...response, ongoing_startups };
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }
}
