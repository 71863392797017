import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ManageCommitModal from '../modals/ManageCommitModal';
import { Formik } from 'formik';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { Alert, Box, Stack } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import BackersManager from '../../backers/manager/BackersManager';
import { AuthContext } from '../../../contexts/authContext';
import { toast } from 'react-toastify';
import DealsManager from '../manager/DealsManager';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { API_STATUS } from '../../common/constants/CommonConstants';
import { ManageCommitOptions } from '../constants/IndividualDealConstant';

const PublishDeal = () => {
  const { id = '' } = useParams();
  const location = useLocation();
  const startupName = location?.state?.startupName;
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [showManageCommitModal, setSMCM] = useState(false);
  const authContext = useContext(AuthContext);
  const backerManager = new BackersManager(authContext);
  const dealManager = new DealsManager(authContext);
  const [tableData, setTableData] = useState([]);
  const [selectedBackers, setSelectedBackers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);

  const [manageCommitSubmissionStatus, setManageCommitSubmissionStatus] = useState(API_STATUS.SUCCESS);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: `DEALS ${startupName ? `- ${startupName}` : ''}`,
        titleDisplay: true
      },
      {
        title: 'Publish Deal',
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Select Backers and Publish Deal',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      renderCell: (params: any) => <Link to={`/backers/${params?.row?.investor_id}`}>{params?.row?.name}</Link>
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 330,
      headerName: 'EMAIL'
    },
    {
      field: 'manage_commit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'MANAGE COMMIT',
      renderCell: (params: any) =>
        params?.row?.manage_commit_amount &&
        `${params?.row?.manage_commit_condition} : ${params?.row?.formatted_manage_commit_amount}`
    },
    {
      field: 'onboarding_status',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'ONBOARDING STATUS'
    },
    {
      field: 'total_transferred',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'TOTAL TRANSFERRED',
      renderCell: (params: any) =>
        params?.row?.total_amount_transferred > 0 ? params?.row?.formatted_total_amount_transferred : '-',
      valueGetter: (params: any) => params?.row?.total_amount_transferred
    },
    {
      field: 'deals',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 100,
      headerName: '#DEALS',
      renderCell: (params: any) => params?.row?.deals_participated ?? '-'
    },
    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      renderCell: (params: any) => (
        <LinkButton
          onClick={() => {
            const formattedRow = {
              ...params.row,
              proposed_amount: params.row.manage_commit_amount,
              condition: ManageCommitOptions?.find(
                (item: any) => item?.value == params?.row?.manage_commit_condition_int
              )
            };
            setSMCM(formattedRow);
          }}
          title="manage commit"
        />
      )
    }
  ];

  const handleRowChange = (row: any) => {
    const selectedBackersArray = tableData.filter((data: any) => row.includes(data.id)).map((data: any) => data.id);
    setSelectedBackers([...selectedBackersArray]);
  };

  const getBackerList = async (permalink: string) => {
    try {
      setIsLoading(true);
      const response = await backerManager.getBackers(permalink, id);
      if (response) setTableData(response);
      setIsLoading(false);
    } catch (e: any) {
      toast.error(e.message || 'An error occurred while fetching data');
      setIsLoading(false);
    }
  };

  const launchDeal = async (value: any) => {
    try {
      setInviteLoading(true);
      const formattedData = {
        startupId: id,
        fass: true,
        mode_of_communication: value?.medium,
        ...(selectedBackers.length > 0 && { backer_list: selectedBackers })
      };
      const response = await dealManager.launchSyndicateDeal({ data: formattedData });
      if (response) {
        toast.success(`Deal published successfully to selected backers`);
        navigate(-1);
      }
      setInviteLoading(false);
    } catch (e: any) {
      toast.error(e.message || 'An error occurred while publishing the deal');
      setInviteLoading(false);
    }
  };

  const manageCommit = async (values: any) => {
    try {
      setManageCommitSubmissionStatus(API_STATUS.LOADING);
      await dealManager.manageCommitement(id, showManageCommitModal, values);
      toast.success('successfully updated commitment');
      setManageCommitSubmissionStatus(API_STATUS.SUCCESS);
      setSMCM(false);
      getBackerList('');
    } catch (e: any) {
      toast.error(e);
      setSMCM(false);
    }
  };

  useEffect(() => {
    getBackerList('');
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <ManageCommitModal
        openModal={showManageCommitModal}
        apiLoading={manageCommitSubmissionStatus === API_STATUS.LOADING}
        handleSubmit={manageCommit}
        handleClose={() => setSMCM(false)}
      />
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
      </Stack>

      {isLoading ? (
        <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={tableData}
          columns={columnsData}
          leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
          activeFilters={filters}
          checkboxSelection
          onselectionChange={handleRowChange}
          getDataOnSelectionChange="id"
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}

      <Box className="text-left" mt={3}>
        <Formik
          initialValues={{ medium: 'email' }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ values }) => (
            <Stack gap={2} pl={0.5} textAlign="start">
              <RadioButtonSelect
                fieldName="medium"
                id="medium"
                value={values?.medium}
                formLabel="Invite Backers to this Deal via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email' },
                  { name: 'Whatsapp', value: 'whatsapp' },
                  { name: 'Both', value: 'both' }
                ]}
              />

              {values?.medium == 'both' ||
                (values?.medium == 'whatsapp' && (
                  <Alert severity="warning">
                    Invite will not be received via whatsapp if mobile number is not registered on platform
                  </Alert>
                ))}

              <Stack direction="row" gap={1}>
                {/* <ActionButton
                  disabled={!selectedBackers.length || testLoading}
                  isLoading={testLoading}
                  onClick={() => setTestLoading(true)}
                >
                  Send Test via {values.medium === 'both' ? 'email & whatsapp' : values?.medium}
                </ActionButton> */}
                <ActionButton
                  disabled={!selectedBackers.length || inviteLoading}
                  isLoading={inviteLoading}
                  onClick={() => launchDeal(values)}
                >
                  Send Invite via {values.medium === 'both' ? 'email & whatsapp' : values?.medium}
                </ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default PublishDeal;
