import React, { FC } from 'react';
import { Box, Chip as ChipMUI } from '@mui/material';
import PropTypes from 'prop-types';
import useChipStyles from './useChipStyles';
const FilterChip: FC<any> = (props: any) => {
  const { label, value, icon, active, onClick } = props;
  const [chipStyles, activeStyle, iconStyles] = useChipStyles(active);

  return (
    <ChipMUI
      label={label}
      icon={active && <Box style={iconStyles}>{icon}</Box>}
      sx={{ ...chipStyles, ...activeStyle }}
      onClick={() => onClick && onClick(value)}
    />
  );
};

FilterChip.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.elementType,
  active: PropTypes.bool,
  onClick: PropTypes.func
};
export default FilterChip;
