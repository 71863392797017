import { Alert, Box, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from '../../../ui-library/components/navs/breadcrumb';
import { LvComplexTable } from '../../../ui-library/components/tables/lv-complex-table';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid';
import { LinkButton } from '../../../ui-library/components/buttons/link-button';
import ManageCommitModal from '../modals/ManageCommitModal';
import { Formik } from 'formik';
import { RadioButtonSelect } from '../../../ui-library/components/form-elements/RadioButtonSelect';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import { toast } from 'react-toastify';
import DealsManager from '../manager/DealsManager';
import { AuthContext } from '../../../contexts/authContext';
import { TablePlaceholder } from '../../../ui-library/components/placeholders/table-placeholder/TablePlaceholder';
import { ManageCommitOptions } from '../constants/IndividualDealConstant';

const InviteBackerThisDeal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { startupData, syncAdminsForDeal = [] } = state;
  if (!startupData) navigate(-1);

  const authContext = useContext(AuthContext);
  const dealManager = new DealsManager(authContext);

  const [uninvitedBackers, setUninvitedBackers] = useState([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [filters, setFilters] = useState([]);
  const [showManageCommitModal, setSMCM] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [manageCommitSubmitting, setManageCommitSubmitting] = useState(false);

  const breadcrumbData = {
    breadcrumbSeparator: true,
    stepKey: 2,
    steps: [
      {
        title: 'DASHBOARD - DEALS',
        titleDisplay: true
      },
      {
        title: startupData?.name,
        titleDisplay: true
      }
    ],
    pageHeader: {
      header: 'Invite Backers to this Deal',
      navigationButton: true,
      navigationFunction: () => navigate(-1)
    }
  };

  const columnsData = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'NAME',
      renderCell: (params: any) => <Link to={`/backers/${params?.row?.investor_id}`}>{params?.row?.name}</Link>
    },
    {
      field: 'email',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 320,
      headerName: 'EMAIL'
    },
    {
      field: 'manage_commit',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 200,
      headerName: 'MANAGE COMMIT',
      renderCell: (params: any) =>
        params?.row?.manage_commit_amount &&
        `${params?.row?.manage_commit_condition} : ${params?.row?.formatted_manage_commit_amount}`
    },
    {
      field: 'onboarding_status',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 170,
      headerName: 'ONBOARDING STATUS'
    },
    {
      field: 'total_amount_transferred',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      minWidth: 150,
      headerName: 'TOTAL TRANSFERRED',
      renderCell: (params: any) => params?.row?.formatted_total_amount_transferred
    },
    {
      field: 'total_deals',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      minWidth: 100,
      headerName: '#DEALS'
    },
    {
      field: 'actions',
      minWidth: 250,
      headerName: 'ACTIONS',
      renderCell: (params: any) => {
        return (
          <LinkButton
            disabled={params?.row?.admin}
            onClick={() => {
              const formattedRow = {
                ...params.row,
                proposed_amount: params.row.manage_commit_amount,
                condition: ManageCommitOptions?.find(
                  (item: any) => item?.value == params?.row?.manage_commit_condition_int
                )
              };
              setSMCM(formattedRow);
            }}
            title="manage commit"
          />
        );
      }
    }
  ];

  const getUninvitedBackers = async () => {
    try {
      setIsPageLoading(true);
      const res = await dealManager.getUninvitedBackersForDeal(id);
      setIsPageLoading(false);
      setUninvitedBackers(res?.backers ?? []);
    } catch (e: any) {
      toast.error(e);
      setIsPageLoading(false);
    }
  };

  const inviteSelectedBacker = async (values: any, isTest?: boolean) => {
    try {
      let medium = values?.medium;
      isTest ? setTestLoading(true) : setInviteLoading(true);
      await dealManager.inviteBackersForDeal(startupData?.id, medium, selectedRows, isTest);
      toast.success(isTest ? 'successfully sent test email' : 'successfully invited selected backers');
      navigate(-1);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setTestLoading(false);
      setInviteLoading(false);
    }
  };

  const manageCommit = async (values: any) => {
    try {
      setManageCommitSubmitting(true);
      await dealManager.manageCommitement(startupData?.id, showManageCommitModal, values);
      toast.success('successfully updated commitment');
      setManageCommitSubmitting(false);
      setSMCM(false);
      getUninvitedBackers();
    } catch (e: any) {
      toast.error(e);
      setManageCommitSubmitting(false);
      setSMCM(false);
    }
  };

  useEffect(() => {
    getUninvitedBackers();
  }, []);

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <ManageCommitModal
        openModal={showManageCommitModal}
        handleClose={() => {
          setSMCM(false);
        }}
        handleSubmit={manageCommit}
        apiLoading={manageCommitSubmitting}
      />
      <Stack direction="row" justifyContent="space-between">
        <Breadcrumb
          steps={breadcrumbData.steps}
          breadcrumbSeparator={breadcrumbData.breadcrumbSeparator}
          pageHeader={breadcrumbData.pageHeader}
          stepKey={breadcrumbData.stepKey}
        />
      </Stack>

      {isPageLoading ? (
        <TablePlaceholder rows={5} columns={5} rowHeight="40px" columnHeaderHeight="40px" />
      ) : (
        <LvComplexTable
          rows={[...uninvitedBackers, ...syncAdminsForDeal]}
          columns={columnsData}
          leftPinnedColumns={[GRID_CHECKBOX_SELECTION_FIELD, 'name']}
          activeFilters={filters}
          checkboxSelection
          onselectionChange={(selectedRows: any) => setSelectedRows(selectedRows)}
          isRowSelectable={(params: any) => !params.row.admin}
          getRowClassName={(params: any) => {
            return params?.row?.admin ? 'isAdmin' : '';
          }}
          customStyles={{
            '.isAdmin': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              ':hover::after': {
                display: 'block',
                position: 'absolute',
                content: '"This user is an Admin for this syndicate. Values cannot be modified for them."',
                border: '1px solid #ccc',
                background: '#fff',
                padding: '.25em',
                borderRadius: '.25em'
              }
            }
          }}
          getDataOnSelectionChange="id"
          onFilterChange={(filters: any) => setFilters(filters)}
          rowHeight={48}
          headerHeight={50}
        />
      )}

      <Box className="text-left" mt={3} textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{ medium: 'email' }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ values }) => (
            <Stack gap={2} pl={0.5}>
              <RadioButtonSelect
                fieldName="medium"
                id="medium"
                value={values?.medium}
                formLabel="Invite Backers to this Deal via Email or WhatsApp?"
                options={[
                  { name: 'Email', value: 'email' },
                  { name: 'Whatsapp', value: 'whatsapp' },
                  { name: 'Both', value: 'both' }
                ]}
              />

              {(values?.medium == 'both' || values?.medium == 'whatsapp') && (
                <Alert severity="warning">
                  Invite will not be received via whatsapp if mobile number is not registered on platform
                </Alert>
              )}

              <Stack direction="row" gap={1}>
                <ActionButton
                  disabled={!selectedRows.length || testLoading}
                  isLoading={testLoading}
                  onClick={() => inviteSelectedBacker(values, true)}
                >
                  Send Test via {values.medium === 'both' ? 'email & whatsapp' : values?.medium}
                </ActionButton>
                <ActionButton
                  disabled={!selectedRows.length || inviteLoading}
                  isLoading={inviteLoading}
                  onClick={() => inviteSelectedBacker(values)}
                >
                  Send Invite via {values.medium === 'both' ? 'email & whatsapp' : values?.medium}
                </ActionButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default InviteBackerThisDeal;
