import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Stack } from '@mui/material';
import { OptionWithEmployeesStatsticCard } from '../../../ui-library/components/card/option-with-employees-statstic-card';
import { useNavigate } from 'react-router-dom';
import { SectionHeader } from '../../../ui-library/components/header/section-header';
import { ActionButton } from '../../../ui-library/components/buttons/action-button';
import DraftDeal from '../../deals/components/DraftDeal';
import LiveDeal from '../../deals/components/LiveDeal';
import { toast } from 'react-toastify';
import DashboardManager from '../manager/DashboardManager';
import { AuthContext } from '../../../contexts/authContext';
import DashboardSkeleton from '../skeletons/DashboardSkeleton';
import NoLiveDeal from './NoLiveDeal';
import { API_STATUS } from '../../common/constants/CommonConstants';

function Dashboard() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new DashboardManager(authContext);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);

  const navigate = useNavigate();
  const navigateToLaunch = () => {
    navigate('/deals/create');
  };

  const getDashboardData = async () => {
    try {
      const response = await dashboardManager.getDashboardDetail();
      setDashboardData(response);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error('Something went wrong');
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  // if (apiStatus === API_STATUS.ERROR) {
  //   return <NoDataAvailable title="Error" subTitle={'Something went wrong'} image={IconConstant.DATA_NOT_FOUND_ICON} />;
  // }

  if (apiStatus === API_STATUS.LOADING) {
    return <DashboardSkeleton />;
  }

  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title="PORTFOLIO COMPANIES"
            value={dashboardData?.total_portfolio_companies ? dashboardData?.total_portfolio_companies : '-'}
            width="250px"
            height="94px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title="TOTAL INVESTED"
            value={dashboardData?.total_amount_invested ? dashboardData?.formated_total_amount_invested : '-'}
            height="94px"
            width="250px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <OptionWithEmployeesStatsticCard
            title="BACKERS"
            value={dashboardData?.all_backers_count ? dashboardData?.all_backers_count : '0'}
            height="94px"
            width="250px"
            onClick={() => navigate('/backers')}
          />
        </Grid>
      </Grid>

      {dashboardData?.ongoing_startups?.length ? (
        <Box mt={3}>
          <Stack direction="row" justifyContent="space-between" mb={1} alignItems="center">
            <SectionHeader>Live Deals</SectionHeader>

            <ActionButton onClick={navigateToLaunch}>Launch New Deal</ActionButton>
          </Stack>
          {dashboardData?.ongoing_startups?.map((item: any, index: any) => (
            <LiveDeal startupData={item} key={index} />
          ))}
        </Box>
      ) : (
        <NoLiveDeal />
      )}
      {dashboardData?.saved_startups?.length ? (
        <Box mt={3}>
          <Stack direction="row" justifyContent="space-between" mb={1} alignItems="center">
            <SectionHeader>Deals in Pipeline</SectionHeader>
          </Stack>
          {dashboardData?.saved_startups?.map((item: any, index: any) => (
            <DraftDeal startupData={item} key={index} />
          ))}
        </Box>
      ) : null}
    </Box>
  );
}

export default Dashboard;
