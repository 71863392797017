import React, { CSSProperties, useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  TextField,
  InputAdornment,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Typography,
  IconButton
} from '@mui/material';

import { useDropzone } from 'react-dropzone';
import useStyles, {
  inputAdormentStyle,
  browseButtonStyle,
  dialogBBoxStyle,
  inputAdormentStyleButtonOnly
} from './style';
import { getColor } from '../../../colors';
import { HorizontalTabs } from '../../tabs/horizontal-tabs';
import { OutlinedButton } from '../../buttons/outlined-button';
import { ActionButton } from '../../buttons/action-button';
import { fileValidator } from '../../utils/Validations';

const CustomDropZone = (props: any) => {
  const { multiple, maxFileSizeLimit, accept, selectedFiles, value, fileValidator } = props;
  const classes = useStyles();
  const [allFiles, setAllFiles] = useState<any>([]);
  const useMergeState = (initialState: any) => {
    const [state, setState] = useState(initialState);
    const setMergedState = (newState: any) =>
      setState((prevState: any) => {
        return { ...prevState, ...newState };
      });
    return [state, setMergedState];
  };
  const [dropZoneState, setDropZoneState] = useMergeState({
    files: value || [],
    rejectedFileList: []
  });

  const { files, rejectedFileList } = dropZoneState;

  const onDropAccepted = useCallback((acceptedFiles: any) => {
    setDropZoneState({ files: acceptedFiles });
  }, []);

  const onDropRejected = useCallback((rejectedFiles: any) => {
    setDropZoneState({ rejectedFileList: rejectedFiles });
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple,
    accept,
    noClick: true,
    noKeyboard: true,
    maxSize: maxFileSizeLimit,
    onDropAccepted,
    onDropRejected,
    validator: fileValidator,
    useFsAccessApi: false
  });

  useEffect(() => {
    setAllFiles([...allFiles, ...files]);
    selectedFiles([...allFiles, ...files]);
  }, [files]);

  const deleteFile = (deletedFile: any, files: any, rejectedDrop = false) => {
    let tempFiles = [];
    if (rejectedDrop) {
      tempFiles = files.filter((fileData: any) => fileData.file !== deletedFile);
      setDropZoneState({ rejectedFileList: tempFiles });
    } else {
      setAllFiles([]);
      tempFiles = files.filter((file: any) => file !== deletedFile);
      setDropZoneState({ files: tempFiles });
    }
  };

  const rejectedFile = rejectedFileList?.map(({ file, errors }: any) => (
    <React.Fragment key={file.path}>
      <div className={classes.rejectedFile}>
        <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/File.svg" alt="file-icon" className={classes.fileIcon} />
        <div style={{ textOverflow: 'ellipsis', width: '340px' }}>
          <span className={classes.uploadedFileTitle}>
            {file.name
              ? file.name
              : file.path
              ? file.path.length > 40
                ? file.path.substring(0, 40) + '.....'
                : file.path
              : ''}
          </span>
        </div>
        <div style={{ alignItems: 'end', marginRight: '4px' }}>
          <IconButton onClick={() => deleteFile(file, rejectedFileList, true)}>
            <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/DeleteRed.svg" alt="delete-icon" />
          </IconButton>
        </div>
      </div>
      <ul className={classes.errorList}>
        {errors?.map((error: any, errorIndex: any) => (
          <li className={classes.errorText} key={errorIndex}>
            {error.message}
          </li>
        ))}
      </ul>
    </React.Fragment>
  ));

  const acceptedFile = allFiles?.map((file: any) => (
    <div key={file.path} className={classes.uploadedFile}>
      <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/File.svg" alt="file-icon" className={classes.fileIcon} />
      <div style={{ textOverflow: 'ellipsis', width: '340px' }}>
        <span className={classes.uploadedFileTitle}>
          {file.name
            ? file.name
            : file.path
            ? file.path.length > 40
              ? file.path.substring(0, 40) + '.....'
              : file.path
            : ''}
        </span>
      </div>
      <div style={{ alignItems: 'end', marginRight: '4px' }}>
        <IconButton onClick={() => deleteFile(file, allFiles)}>
          <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/DeleteRed.svg" alt="delete-icon" />
        </IconButton>
      </div>
    </div>
  ));

  return (
    <div className="container">
      {allFiles.length === 0 || multiple ? (
        <div {...getRootProps({ className: `${classes.dropzone}` })} onClick={open}>
          <input {...getInputProps()} />
          <img src="https://dsjvxb1plg419.cloudfront.net/v2.0/fileUpload.svg" alt="file-upload" />
          <Typography fontWeight={500} fontSize="14px" color={getColor('neutral', 900)}>
            Drag & drop file or Browse
          </Typography>
        </div>
      ) : null}
      {acceptedFile}
      {rejectedFile}
    </div>
  );
};

interface acceptType {
  [key: string]: string[];
}

export type FileUploadProps = {
  /**
   * custom styles for the textfield.
   */
  customStyles?: CSSProperties;
  /**
   *name of the textField.
   */
  label?: String;
  /**
   *width of the textField.
   */
  width?: string | number;
  /**
   *here you can trigger onChange function.
   */
  onChange?: any;
  /**
   *if this is true, it will make the field mandatory.
   */
  required?: boolean;
  /**
   *InputLabel Props for textfield component.
   */
  InputLabelProps?: any;
  /**
   * Title of the dialog box for file upload.
   */
  title?: string;
  /**
   * if true multiple file upload is enabled
   */
  multiple?: boolean;
  /**
   * maximum file size limit
   */
  maxFileSizeLimit?: number;
  /**
   * only accepts the required file format
   */
  accept?: acceptType;
  /**
   * returns the selected File Data ex: {'image/svg': ['.png', '.svg'],'text/pdf': ['.pdf',]}
   */
  selectedFile?: Function;
  /**
   *helper text for the textfield component.
   */
  helperText?: any;
  /**
   *If true, the label is displayed in an error state.
   */
  error?: boolean;
  /**
   *the label is for error message
   */
  errorMsg?: any;
  /**
   * if true disables the file upload
   */
  disabled?: boolean;
  /**
   * to pass the file list array
   */
  value?: any[];
  /**
   *placeholder for the textfield.
   */
  placeholder?: string;
  /**
   * To hide the document vault
   */
  hideDocumentVault?: boolean;
  /**
   * Custom validation function. It must return null if there's no errors.
   */
  customFileValidator?: Function;
  /**
   * Display only the browse button
   */
  hideInputArea?: boolean;
  /**
   * Disable default file name validation
   */
  disableDefaultValidation?: boolean;
};

export function FileUpload({
  placeholder = 'select file...',
  width = '577px',
  customStyles,
  label,
  onChange,
  required = false,
  InputLabelProps,
  helperText,
  title = 'Upload File',
  error,
  hideDocumentVault = false,
  multiple = false,
  value = [],
  accept,
  maxFileSizeLimit,
  selectedFile,
  disabled = false,
  customFileValidator,
  errorMsg,
  hideInputArea = false,
  disableDefaultValidation = false
}: FileUploadProps) {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('upload');
  const [files, setFiles] = useState<any>(value);
  const [fileName, setFileName] = useState<string>('');
  let adornmentStyle = inputAdormentStyle;

  const classes = useStyles();

  const handleClick = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  if (hideInputArea) {
    adornmentStyle = { ...adornmentStyle, ...inputAdormentStyleButtonOnly };
  }
  useEffect(() => {
    const selectedFileName = value?.map((file) => file?.name ?? file?.path)?.join(', ');
    setFileName(selectedFileName);
  }, [value]);

  const handleSelect = () => {
    selectedFile && selectedFile(files);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setFiles(value);
  };

  const tabs = [
    {
      name: 'UPLOAD',
      value: 'upload',
      content: (
        <CustomDropZone
          multiple={multiple}
          maxFileSizeLimit={maxFileSizeLimit}
          accept={accept}
          selectedFiles={(files: any) => {
            setFiles(files);
          }}
          value={files}
          fileValidator={(file: any) => fileValidator(file, disableDefaultValidation, customFileValidator)}
        />
      )
    },
    {
      name: 'DOCUMENT VAULT',
      value: 'doc_vault',
      content: <p>DOCUMENT VAULT</p>,
      disabled: true
    }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        disabled={disabled}
        className={`${classes.root} ${hideInputArea ? classes.hideInputArea : ''}`}
        value={fileName}
        onClick={handleClick}
        variant="outlined"
        multiline={multiple}
        type="string"
        label={label}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        error={error}
        sx={{
          ...customStyles,
          width: width,
          input: { color: getColor('border', 200) }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={adornmentStyle} position="end">
              <OutlinedButton disabled={disabled} customStyles={browseButtonStyle} onClick={handleClick}>
                Browse
              </OutlinedButton>
            </InputAdornment>
          )
        }}
        InputLabelProps={InputLabelProps}
        helperText={errorMsg}
      />
      {helperText && (
        <Typography variant="caption_regular" marginTop="5px" textAlign="left">
          <span style={{ color: getColor('neutral', 600) }}>{helperText}</span>
        </Typography>
      )}
      <Dialog open={open} sx={dialogBBoxStyle(hideDocumentVault)}>
        <DialogTitle sx={{ padding: '30px 30px 20px', fontSize: '18px', fontWeight: '600', fontFamily: 'Work Sans' }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ padding: '0px 30px' }}>
          <Box className={classes.dialogContent}>
            {hideDocumentVault ? (
              <CustomDropZone
                multiple={multiple}
                maxFileSizeLimit={maxFileSizeLimit}
                accept={accept}
                selectedFiles={(files: any) => {
                  setFiles(files);
                }}
                value={files}
                fileValidator={(file: any) => fileValidator(file, disableDefaultValidation, customFileValidator)}
              />
            ) : (
              <HorizontalTabs
                tabs={tabs}
                selectedTab={selectedTab}
                onTabChange={(tab: any) => setSelectedTab(tab)}
                customTabsStyles={{
                  width: '418px',
                  height: '44px',
                  borderRadius: '4px'
                }}
                customTabStyles={{
                  fontFamily: 'Work Sans',
                  lineHeight: '21px',
                  textAlign: 'center',
                  letterSpacing: '0.5px',
                  fontStyle: 'normal'
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: '30px', justifyContent: 'center', gap: '12px' }}>
          <OutlinedButton customStyles={{ width: '199px' }} onClick={() => handleCancel()}>
            CANCEL
          </OutlinedButton>
          <ActionButton customStyles={{ width: '199px' }} disabled={!files?.length} onClick={() => handleSelect()}>
            SELECT
          </ActionButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
